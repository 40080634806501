import VENUE_UPGRADING_WIZARD_ACTIONS from '@/venue_management_domain/venue_upgrade_wizard/vuex/venue-upgrading-wizard-actions';
var VENUE_UPGRADING_WIZARD_STORE = {
  state: function state() {
    return {
      venueId: '',
      shouldConnectToUser: false,
      // fetch venue detail after complete login or registration
      shouldFetchVenueDetail: false,
      shouldShowRegisterButtonInLoginModal: true
    };
  },
  mutations: {
    updateVenueId: function updateVenueId(state, venueId) {
      state.venueId = venueId;
    },
    updateShouldConnectToUser: function updateShouldConnectToUser(state, shouldConnectToUser) {
      state.shouldConnectToUser = shouldConnectToUser;
    },
    updateShouldFetchVenueDetail: function updateShouldFetchVenueDetail(state, shouldFetchVenueDetail) {
      state.shouldFetchVenueDetail = shouldFetchVenueDetail;
    },
    updateShouldShowRegisterButtonInLoginModal: function updateShouldShowRegisterButtonInLoginModal(state, shouldShowRegisterButtonInLoginModal) {
      state.shouldShowRegisterButtonInLoginModal = shouldShowRegisterButtonInLoginModal;
    }
  },
  getters: {
    venueId: function venueId(state) {
      return state.venueId;
    },
    shouldConnectToUser: function shouldConnectToUser(state) {
      return state.shouldConnectToUser;
    },
    shouldFetchVenueDetail: function shouldFetchVenueDetail(state) {
      return state.shouldFetchVenueDetail;
    },
    shouldShowRegisterButtonInLoginModal: function shouldShowRegisterButtonInLoginModal(state) {
      return state.shouldShowRegisterButtonInLoginModal;
    }
  },
  actions: {
    updateVenueId: function updateVenueId(_ref, venueId) {
      var commit = _ref.commit;
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateVenueId, venueId);
    },
    updateShouldConnectToUser: function updateShouldConnectToUser(_ref2, shouldConnectToUser) {
      var commit = _ref2.commit;
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldConnectToUser, shouldConnectToUser);
    },
    updateShouldFetchVenueDetail: function updateShouldFetchVenueDetail(_ref3, shouldFetchVenueDetail) {
      var commit = _ref3.commit;
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldFetchVenueDetail, shouldFetchVenueDetail);
    },
    updateShouldShowRegisterButtonInLoginModal: function updateShouldShowRegisterButtonInLoginModal(_ref4, shouldShowRegisterButtonInLoginModal) {
      var commit = _ref4.commit;
      commit(VENUE_UPGRADING_WIZARD_ACTIONS.updateShouldShowRegisterButtonInLoginModal, shouldShowRegisterButtonInLoginModal);
    }
  }
};
export default VENUE_UPGRADING_WIZARD_STORE;