var AUTHENTICATION_ACTIONS = {
  resetAdminTimezoneToOriginalOne: 'resetAdminTimezoneToOriginalOne',
  updateOriginalAdminTimezone: 'updateOriginalAdminTimezone',
  updateUserSettings: 'updateUserSettings',
  updateTranslatorMode: 'updateTranslatorMode',
  clearUserSettings: 'clearUserSettings',
  updateShowPriceInclVat: 'updateShowPriceInclVat',
  updateUser: 'updateUser',
  clearUser: 'clearUser',
  clearNextRoute: 'clearNextRoute',
  updateEventIdInCaseOrganizationHasOnlyOneEvent: 'updateEventIdInCaseOrganizationHasOnlyOneEvent',
  clearEventIdInCaseOrganizationHasOnlyOneEvent: 'clearEventIdInCaseOrganizationHasOnlyOneEvent',
  openLoginModal: 'openLoginModal',
  closeLoginModal: 'closeLoginModal',
  openLoginToOrganizationModal: 'openLoginToOrganizationModal',
  closeLoginToOrganizationModal: 'closeLoginToOrganizationModal',
  openRegisterModal: 'openRegisterModal',
  closeRegisterModal: 'closeRegisterModal',
  updateOfflineEvent: 'updateOfflineEvent',
  clearOfflineEvent: 'clearOfflineEvent',
  openRegisterOrganizationFromUserProfileModal: 'openRegisterOrganizationFromUserProfileModal',
  closeRegisterOrganizationFromUserProfileModal: 'closeRegisterOrganizationFromUserProfileModal',
  startReloadOrganizationListInUserProfile: 'startReloadOrganizationListInUserProfile',
  stopReloadOrganizationListInUserProfile: 'stopReloadOrganizationListInUserProfile',
  updatePreferredBrowserLanguageCode: 'updatePreferredBrowserLanguageCode',
  updateCountryCodeFromUserBrowser: 'updateCountryCodeFromUserBrowser',
  logOut: 'logOut',
  updateShouldShowReleaseNoteNotification: 'updateShouldShowReleaseNoteNotification',
  updatePlannedEventNumber: 'updatePlannedEventNumber',
  updateEPRequestQuoteWizardVisible: 'updateEPRequestQuoteWizardVisible',
  updateAuthenticatedUserPossibleActions: 'updateAuthenticatedUserPossibleActions',
  updateCurrentOrganization: 'updateCurrentOrganization',
  clearCurrentOrganization: 'clearCurrentOrganization'
};
export default AUTHENTICATION_ACTIONS;