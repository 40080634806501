import has from 'lodash/has';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
var Locale = 'locale';
var AUTHENTICATION_STORE = {
  state: function state() {
    return {
      /**
       * User information contain these values:
       * user name, full name
       * accountType (deprecated), city, email, phoneNo, photoUrl(user)
       */
      user: null,
      currentOrganization: null,
      /**
       * User settings contain these values:
       * dateFormat, languageCode, numberFormat, showPriceInclVat, username
       */
      userSettings: null,
      /**
       * Show price incl vat is a part of user settings, but we don't want to access whole user settings every time
       * So we split it to another property in state
       */
      showPriceInclVat: true,
      /**
       * When use log in and
       * 1. User has only one organization
       * 2. Organization has only one event
       * 3. Organization is event planner
       *
       * We need to navigate to this only event instead of home page
       * So we store the event id here to check
       */
      eventIdInCaseOrganizationHasOnlyOneEvent: null,
      /**
       * We can show login modal from many places: header, footer, event page
       * So we use a share state for them
       */
      showLoginModal: false,
      /**
       * When user have many different organizations
       * They can choose to log in / switch between these organizations
       * User can choose organization when
       * 1. After login
       * 2. Switch from menu
       */
      showLoginToOrganizationModal: false,
      /**
       * We can show login modal from many places: header, footer, event page
       * So we use a share state for them
       */
      showRegisterModal: false,
      /**
       * When user is creating an offline event, they can register or login for requesting quotation
       * In this case we need to send the offlineEvent to backend
       * In theory we can get this from local history, but let it here for now
       */
      offlineEvent: {},
      /**
       * User can register new organization from User Profile -> Organizations -> Register New ORG button
       */
      showRegisterOrganizationFromUserProfileModal: false,
      /**
       * get list organizations in User Profile -> Organizations when register new organization successfully
       */
      reloadOrganizationListInUserProfile: false,
      /**
       * in case not logged in any user, we will use the first preferred browser language
       */
      preferredBrowserLanguageCode: '',
      /**
       * which route will go to after login
       */
      nextRoute: null,
      /**
       * the first time we access to Coopax, we will use country code from user browser
       */
      countryCodeFromUserBrowser: null,
      /**
       * Original admin timezone
       */
      originalAdminTimezone: {},
      /**
       * to know if we should show the new release note notification modal to the user or not
       */
      shouldShowReleaseNoteNotification: false,
      /**
       * number of planned event of an org, to check if we will show event dashboard on sidebar
       */
      plannedEventNumber: null,
      /**
       * Event planner request quote wizard visible
       */
      epRequestQuoteWizardVisible: false,
      /**
       * After login, there is some authentication actions that user can do, which define in links of /users/me API
       * We store this because there are many places user can work with authentication in application
       */
      authenticatedUserPossibleActions: [],
      isTranslatorMode: false
    };
  },
  mutations: {
    startReloadOrganizationListInUserProfile: function startReloadOrganizationListInUserProfile(state) {
      state.reloadOrganizationListInUserProfile = true;
    },
    stopReloadOrganizationListInUserProfile: function stopReloadOrganizationListInUserProfile(state) {
      state.reloadOrganizationListInUserProfile = false;
    },
    openRegisterOrganizationFromUserProfileModal: function openRegisterOrganizationFromUserProfileModal(state) {
      state.showRegisterOrganizationFromUserProfileModal = true;
    },
    closeRegisterOrganizationFromUserProfileModal: function closeRegisterOrganizationFromUserProfileModal(state) {
      state.showRegisterOrganizationFromUserProfileModal = false;
    },
    updateUserSettings: function updateUserSettings(state, userSettings) {
      state.userSettings = userSettings;
    },
    updateOriginalAdminTimezone: function updateOriginalAdminTimezone(state, originalAdminTimezone) {
      state.originalAdminTimezone = originalAdminTimezone;
    },
    resetAdminTimezoneToOriginalOne: function resetAdminTimezoneToOriginalOne(state) {
      if (state.originalAdminTimezone && state.originalAdminTimezone.id) {
        state.userSettings.timezone = state.originalAdminTimezone;
        state.userSettings.timezoneId = state.originalAdminTimezone.id;
      }
    },
    updateShowPriceInclVat: function updateShowPriceInclVat(state, showPriceInclVat) {
      state.showPriceInclVat = showPriceInclVat;
    },
    updateCurrentOrganization: function updateCurrentOrganization(state, currentOrganization) {
      state.currentOrganization = currentOrganization;
    },
    clearCurrentOrganization: function clearCurrentOrganization(state) {
      state.currentOrganization = null;
    },
    clearUserSettings: function clearUserSettings(state) {
      state.userSettings = {
        dateFormat: 'nl',
        languageCode: localStorage.getItem(Locale),
        numberFormat: 'nl',
        username: null
      };
    },
    updateUser: function updateUser(state, user) {
      state.user = user;
    },
    clearUser: function clearUser(state) {
      state.user = null;
    },
    clearNextRoute: function clearNextRoute(state) {
      state.nextRoute = null;
    },
    updateEventIdInCaseOrganizationHasOnlyOneEvent: function updateEventIdInCaseOrganizationHasOnlyOneEvent(state, eventId) {
      state.eventIdInCaseOrganizationHasOnlyOneEvent = eventId;
    },
    clearEventIdInCaseOrganizationHasOnlyOneEvent: function clearEventIdInCaseOrganizationHasOnlyOneEvent(state) {
      state.eventIdInCaseOrganizationHasOnlyOneEvent = null;
    },
    openLoginModal: function openLoginModal(state) {
      var nextRoute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      state.showLoginModal = true;
      state.nextRoute = nextRoute;
    },
    closeLoginModal: function closeLoginModal(state) {
      state.showLoginModal = false;
    },
    openLoginToOrganizationModal: function openLoginToOrganizationModal(state) {
      state.showLoginToOrganizationModal = true;
    },
    closeLoginToOrganizationModal: function closeLoginToOrganizationModal(state) {
      state.showLoginToOrganizationModal = false;
    },
    openRegisterModal: function openRegisterModal(state) {
      state.showRegisterModal = true;
    },
    closeRegisterModal: function closeRegisterModal(state) {
      state.showRegisterModal = false;
    },
    updateOfflineEvent: function updateOfflineEvent(state, offlineEvent) {
      state.offlineEvent = offlineEvent;
    },
    updateEPRequestQuoteWizardVisible: function updateEPRequestQuoteWizardVisible(state, epRequestQuoteWizardVisible) {
      state.epRequestQuoteWizardVisible = epRequestQuoteWizardVisible;
    },
    updatePreferredBrowserLanguageCode: function updatePreferredBrowserLanguageCode(state, preferredBrowserLanguageCode) {
      state.preferredBrowserLanguageCode = preferredBrowserLanguageCode;
    },
    updateCountryCodeFromUserBrowser: function updateCountryCodeFromUserBrowser(state, countryCodeFromUserBrowser) {
      state.countryCodeFromUserBrowser = countryCodeFromUserBrowser;
    },
    updateShouldShowReleaseNoteNotification: function updateShouldShowReleaseNoteNotification(state, shouldShowReleaseNoteNotification) {
      state.shouldShowReleaseNoteNotification = shouldShowReleaseNoteNotification;
    },
    logOut: function logOut(state) {
      state.user = null;
      state.currentOrganization = null;
      state.userSettings = {
        dateFormat: 'nl',
        languageCode: localStorage.getItem(Locale),
        numberFormat: 'nl',
        username: null
      };
    },
    updatePlannedEventNumber: function updatePlannedEventNumber(state, plannedEventNumber) {
      state.plannedEventNumber = plannedEventNumber;
    },
    updateAuthenticatedUserPossibleActions: function updateAuthenticatedUserPossibleActions(state, authenticatedUserPossibleActions) {
      state.authenticatedUserPossibleActions = authenticatedUserPossibleActions;
    },
    updateTranslatorMode: function updateTranslatorMode(state, mode) {
      state.isTranslatorMode = mode;
    }
  },
  getters: {
    userTimezone: function userTimezone(state) {
      return state.userSettings.timezone;
    },
    currentOrganization: function currentOrganization(state) {
      return state.currentOrganization;
    },
    shouldFetchOrganizationsInUserProfile: function shouldFetchOrganizationsInUserProfile(state) {
      return state.reloadOrganizationListInUserProfile;
    },
    showRegisterOrganizationFromUserProfileModal: function showRegisterOrganizationFromUserProfileModal(state) {
      return state.showRegisterOrganizationFromUserProfileModal;
    },
    numberFormat: function numberFormat(state) {
      return state.userSettings.numberFormat;
    },
    showLoginModal: function showLoginModal(state) {
      return state.showLoginModal;
    },
    showLoginToOrganizationModal: function showLoginToOrganizationModal(state) {
      return state.showLoginToOrganizationModal;
    },
    showRegisterModal: function showRegisterModal(state) {
      return state.showRegisterModal;
    },
    isAdmin: function isAdmin(state) {
      return state.user && state.user.isLoggedInAsAdmin;
    },
    isSuperAdminRole: function isSuperAdminRole(state) {
      return state.user && state.user.isSuperAdminRole;
    },
    isAdminRole: function isAdminRole(state) {
      return state.user && state.user.isNormalAdminRole;
    },
    isHelpdeskRole: function isHelpdeskRole(state) {
      return state.user && state.user.isHelpdeskRole;
    },
    isLoggedIn: function isLoggedIn(state) {
      return !!state.user;
    },
    isLoggedInToOrganization: function isLoggedInToOrganization(state) {
      return !!(state.user && state.currentOrganization);
    },
    offlineEvent: function offlineEvent(state) {
      return state.offlineEvent;
    },
    isVenueManager: function isVenueManager(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'venue_organization_logged_in';
      });
    },
    isEventPlanner: function isEventPlanner(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'event_planner_logged_in';
      });
    },
    isSupplier: function isSupplier(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'supplier_logged_in';
      });
    },
    isClient: function isClient(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'personal_client_logged_in' || link.rel === 'business_client_logged_in';
      });
    },
    isExhibitor: function isExhibitor(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'exhibitor_organization_logged_in';
      });
    },
    isTranslator: function isTranslator(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'update_one_translation_key';
      });
    },
    isTranslatorMode: function isTranslatorMode(state) {
      return state.isTranslatorMode;
    },
    currentUser: function currentUser(state) {
      return state.user;
    },
    currentOrganizationId: function currentOrganizationId(state) {
      return state.currentOrganization && state.currentOrganization.id;
    },
    isPersonalAccount: function isPersonalAccount(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'personal_client_logged_in';
      });
    },
    isBusinessAccount: function isBusinessAccount(state) {
      return state.authenticatedUserPossibleActions.some(function (link) {
        return link.rel === 'business_client_logged_in';
      });
    },
    userLanguage: function userLanguage(state) {
      return state.userSettings && state.userSettings.languageCode || localStorage.getItem(Locale) || state.preferredBrowserLanguageCode;
    },
    userSettings: function userSettings(state) {
      return state.userSettings;
    },
    shouldShowPriceInclVat: function shouldShowPriceInclVat(state) {
      return state.showPriceInclVat;
    },
    nextRoute: function nextRoute(state) {
      return state.nextRoute;
    },
    countryCodeFromUserBrowser: function countryCodeFromUserBrowser(state) {
      return state.countryCodeFromUserBrowser;
    },
    shouldShowReleaseNoteNotification: function shouldShowReleaseNoteNotification(state) {
      return state.shouldShowReleaseNoteNotification;
    },
    plannedEventNumber: function plannedEventNumber(state) {
      return state.plannedEventNumber;
    },
    epRequestQuoteWizardVisible: function epRequestQuoteWizardVisible(state) {
      return state.epRequestQuoteWizardVisible;
    },
    authenticatedUserPossibleActions: function authenticatedUserPossibleActions(state) {
      return state.authenticatedUserPossibleActions;
    },
    loggedInOrganization: function loggedInOrganization(state) {
      return state.loggedInOrganization;
    }
  },
  actions: {
    /**
     * When admin login to any organization and go back to admin page
     * We need to reset admin timezone to original one
     * @param commit
     * @param userSettings
     */
    resetAdminTimezoneToOriginalOne: function resetAdminTimezoneToOriginalOne(_ref) {
      var commit = _ref.commit;
      commit(AUTHENTICATION_ACTIONS.resetAdminTimezoneToOriginalOne);
    },
    /**
     * When admin login to any organization
     * We need to update the original admin timezone
     * @param commit
     * @param originalAdminTimezone
     */
    updateOriginalAdminTimezone: function updateOriginalAdminTimezone(_ref2, originalAdminTimezone) {
      var commit = _ref2.commit;
      commit(AUTHENTICATION_ACTIONS.updateOriginalAdminTimezone, originalAdminTimezone);
    },
    /**
     * After login or change user settings - language, date config and price config
     * We need to update the user settings in store, so all the application will run with new settings
     * @param commit
     * @param userSettings
     */
    updateUserSettings: function updateUserSettings(_ref3, userSettings) {
      var commit = _ref3.commit;
      // for login user, we use showPriceInclVat config from userSettings
      // Admin user doesn't have userSettings
      if (has(userSettings, 'showPriceInclVat')) {
        commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, userSettings.showPriceInclVat);
      } else {
        commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, localStorage.getItem('showPriceInclVat') === 'true');
      }
      commit(AUTHENTICATION_ACTIONS.updateUserSettings, userSettings);
    },
    /**
     * When switch between price incl vat and price excl vat in user settings or in footer
     * We need to update the new setting in store, to make the application run with new setting
     * @param commit
     * @param showPriceInclVat
     */
    updateShowPriceInclVat: function updateShowPriceInclVat(_ref4, showPriceInclVat) {
      var commit = _ref4.commit;
      commit(AUTHENTICATION_ACTIONS.updateShowPriceInclVat, showPriceInclVat);
    },
    updateCurrentOrganization: function updateCurrentOrganization(_ref5, currentOrganization) {
      var commit = _ref5.commit;
      commit(AUTHENTICATION_ACTIONS.updateCurrentOrganization, currentOrganization);
      localStorage.setItem('organizationId', "".concat(currentOrganization.id));
    },
    clearCurrentOrganization: function clearCurrentOrganization(_ref6) {
      var commit = _ref6.commit;
      commit(AUTHENTICATION_ACTIONS.clearCurrentOrganization, null);
      localStorage.removeItem('organizationId');
    },
    /**
     * When log out, we need to clear user setting
     * @param commit
     */
    clearUserSettings: function clearUserSettings(_ref7) {
      var commit = _ref7.commit;
      commit(AUTHENTICATION_ACTIONS.clearUserSettings, null);
    },
    /**
     * When log in, we need to store the user information (not user profile) - included user name
     * @param commit
     * @param user
     */
    updateUser: function updateUser(_ref8, user) {
      var commit = _ref8.commit;
      commit(AUTHENTICATION_ACTIONS.updateUser, user);
      localStorage.setItem('username', "".concat(user.username));
    },
    /**
     * When log out, we need to clear the user data
     * @param commit
     */
    clearUser: function clearUser(_ref9) {
      var commit = _ref9.commit;
      commit(AUTHENTICATION_ACTIONS.clearUser, null);
      localStorage.removeItem('username');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expired-time');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refresh-access-token-link');
    },
    /**
     * When access to organization, we need to clear the next route
     * @param commit
     */
    clearNextRoute: function clearNextRoute(_ref10) {
      var commit = _ref10.commit;
      commit(AUTHENTICATION_ACTIONS.clearNextRoute);
    },
    /**
     * When log in, we need to check and set the event id in case
     * 1. User has only one organization
     * 2. Organization has only one event
     * 3. Organization is event planner
     * @param eventId
     * @param commit
     */
    updateEventIdInCaseOrganizationHasOnlyOneEvent: function updateEventIdInCaseOrganizationHasOnlyOneEvent(_ref11, eventId) {
      var commit = _ref11.commit;
      commit(AUTHENTICATION_ACTIONS.updateEventIdInCaseOrganizationHasOnlyOneEvent, eventId);
    },
    /**
     * After login and navigate to event page
     * We need to clear the event id in store
     * @param commit
     */
    clearEventIdInCaseOrganizationHasOnlyOneEvent: function clearEventIdInCaseOrganizationHasOnlyOneEvent(_ref12) {
      var commit = _ref12.commit;
      commit(AUTHENTICATION_ACTIONS.clearEventIdInCaseOrganizationHasOnlyOneEvent, null);
    },
    /**
     * Open login modal to login
     * In case we access to any link that require login
     * We use nextRoute to know where we will go next
     * @param commit
     * @param nextRoute
     */
    openLoginModal: function openLoginModal(_ref13, nextRoute) {
      var commit = _ref13.commit;
      commit(AUTHENTICATION_ACTIONS.openLoginModal, nextRoute);
    },
    /**
     * Close login modal
     */
    closeLoginModal: function closeLoginModal(_ref14) {
      var commit = _ref14.commit;
      commit(AUTHENTICATION_ACTIONS.closeLoginModal);
    },
    /**
     * Open login to organization modal
     * @param commit
     */
    openLoginToOrganizationModal: function openLoginToOrganizationModal(_ref15) {
      var commit = _ref15.commit;
      commit(AUTHENTICATION_ACTIONS.openLoginToOrganizationModal);
    },
    /**
     * Close login to organization modal
     * @param commit
     */
    closeLoginToOrganizationModal: function closeLoginToOrganizationModal(_ref16) {
      var commit = _ref16.commit;
      commit(AUTHENTICATION_ACTIONS.closeLoginToOrganizationModal);
    },
    /**
     * Open register modal
     * @param state
     */
    openRegisterModal: function openRegisterModal(_ref17) {
      var commit = _ref17.commit;
      commit(AUTHENTICATION_ACTIONS.openRegisterModal);
    },
    /**
     * Close register modal
     * @param state
     */
    closeRegisterModal: function closeRegisterModal(_ref18) {
      var commit = _ref18.commit;
      commit(AUTHENTICATION_ACTIONS.closeRegisterModal);
    },
    /**
     * Update offline event from offline event page
     * To use in register or login page
     * @param state
     * @param offlineEvent
     */
    updateOfflineEvent: function updateOfflineEvent(_ref19, offlineEvent) {
      var commit = _ref19.commit;
      commit(AUTHENTICATION_ACTIONS.updateOfflineEvent, offlineEvent);
    },
    updateEPRequestQuoteWizardVisible: function updateEPRequestQuoteWizardVisible(_ref20, epRequestQuoteWizardVisible) {
      var commit = _ref20.commit;
      commit(AUTHENTICATION_ACTIONS.updateEPRequestQuoteWizardVisible, epRequestQuoteWizardVisible);
    },
    /**
     * clear offline event from offline event page
     * To use in register or login page
     * @param state
     */
    clearOfflineEvent: function clearOfflineEvent(_ref21) {
      var commit = _ref21.commit;
      commit(AUTHENTICATION_ACTIONS.updateOfflineEvent, null);
    },
    /**
     * Open register new organization modal from User Profile -> Organizations
     * @param commit
     */
    openRegisterOrganizationFromUserProfileModal: function openRegisterOrganizationFromUserProfileModal(_ref22) {
      var commit = _ref22.commit;
      commit(AUTHENTICATION_ACTIONS.openRegisterOrganizationFromUserProfileModal);
    },
    /**
     * close register new organization modal from User Profile -> Organizations
     * @param commit
     */
    closeRegisterOrganizationFromUserProfileModal: function closeRegisterOrganizationFromUserProfileModal(_ref23) {
      var commit = _ref23.commit;
      commit(AUTHENTICATION_ACTIONS.closeRegisterOrganizationFromUserProfileModal);
    },
    /**
     * update the reloadOrganizationListInUserProfile when register organization success
     * when register organization success -> true
     * @param commit
     */
    startReloadOrganizationListInUserProfile: function startReloadOrganizationListInUserProfile(_ref24) {
      var commit = _ref24.commit;
      commit(AUTHENTICATION_ACTIONS.startReloadOrganizationListInUserProfile);
    },
    /**
     * update the reloadOrganizationListInUserProfile when register organization success
     * when done fetching the organization list -> false
     * @param commit
     */
    stopReloadOrganizationListInUserProfile: function stopReloadOrganizationListInUserProfile(_ref25) {
      var commit = _ref25.commit;
      commit(AUTHENTICATION_ACTIONS.stopReloadOrganizationListInUserProfile);
    },
    /**
     * update the preferred browser language when open the application
     * @param commit
     * @param preferredBrowserLanguageCode
     */
    updatePreferredBrowserLanguageCode: function updatePreferredBrowserLanguageCode(_ref26, preferredBrowserLanguageCode) {
      var commit = _ref26.commit;
      commit(AUTHENTICATION_ACTIONS.updatePreferredBrowserLanguageCode, preferredBrowserLanguageCode);
    },
    /**
     * update country code from user browser when open the application in the first time
     * @param commit
     * @param preferredBrowserLanguageCode
     */
    updateCountryCodeFromUserBrowser: function updateCountryCodeFromUserBrowser(_ref27, countryCodeFromUserBrowser) {
      var commit = _ref27.commit;
      commit(AUTHENTICATION_ACTIONS.updateCountryCodeFromUserBrowser, countryCodeFromUserBrowser);
    },
    logOut: function logOut(_ref28) {
      var commit = _ref28.commit;
      commit(AUTHENTICATION_ACTIONS.logOut);
    },
    updateShouldShowReleaseNoteNotification: function updateShouldShowReleaseNoteNotification(_ref29, shouldShowReleaseNoteNotification) {
      var commit = _ref29.commit;
      commit(AUTHENTICATION_ACTIONS.updateShouldShowReleaseNoteNotification, shouldShowReleaseNoteNotification);
    },
    updatePlannedEventNumber: function updatePlannedEventNumber(_ref30, plannedEventNumber) {
      var commit = _ref30.commit;
      commit(AUTHENTICATION_ACTIONS.updatePlannedEventNumber, plannedEventNumber);
    },
    updateAuthenticatedUserPossibleActions: function updateAuthenticatedUserPossibleActions(_ref31, possibleActions) {
      var commit = _ref31.commit;
      commit(AUTHENTICATION_ACTIONS.updateAuthenticatedUserPossibleActions, possibleActions);
    },
    updateTranslatorMode: function updateTranslatorMode(_ref32, mode) {
      var commit = _ref32.commit;
      commit(AUTHENTICATION_ACTIONS.updateTranslatorMode, mode);
    }
  }
};
export default AUTHENTICATION_STORE;