import ADMIN_LANGUAGES_ACTIONS from '@/crm_domain/languages/vuex/admin-languages-actions';
var ADMIN_LANGUAGES_STORE = {
  state: function state() {
    return {
      /**
       * list language of admin that is active
       */
      activeLanguages: [],
      /**
       * list all languages of admin
       */
      languages: []
    };
  },
  mutations: {
    updateActiveLanguages: function updateActiveLanguages(state, activeLanguages) {
      state.activeLanguages = activeLanguages;
    },
    updateLanguages: function updateLanguages(state, languages) {
      state.languages = languages;
    }
  },
  getters: {
    activeLanguages: function activeLanguages(state) {
      return state.activeLanguages;
    },
    allLanguages: function allLanguages(state) {
      return state.languages;
    }
  },
  actions: {
    /**
     * update the languages use for normal users
     * @param commit
     * @param activeLanguages
     */
    updateActiveLanguages: function updateActiveLanguages(_ref, activeLanguages) {
      var commit = _ref.commit;
      commit(ADMIN_LANGUAGES_ACTIONS.updateActiveLanguages, activeLanguages);
    },
    /**
     * update the languages use for admin
     * @param commit
     * @param languages
     */
    updateLanguages: function updateLanguages(_ref2, languages) {
      var commit = _ref2.commit;
      commit(ADMIN_LANGUAGES_ACTIONS.updateLanguages, languages);
    }
  }
};
export default ADMIN_LANGUAGES_STORE;