import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-modal",
    {
      attrs: { value: _vm.visible, persistent: "" },
      on: {
        input: function ($event) {
          return _vm.closeDialog()
        },
      },
    },
    [
      _c("loading-overlay", { attrs: { event: _vm.loadingEvent } }),
      _c(
        "card-in-modal",
        { attrs: { title: _vm.$t("i18n.translate_the_key#header") } },
        [
          _vm._t(
            "default",
            [
              _vm.translationsGroupedByKeyCollection.length > 0
                ? _c(
                    "div",
                    { attrs: { id: "translator-container" } },
                    _vm._l(
                      _vm.translationsGroupedByKeyCollection,
                      function (translationsGroupedByKey) {
                        return _c(
                          "div",
                          {
                            key: translationsGroupedByKey.key,
                            staticClass: "translator--translations-of-key",
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "full-anchored-text",
                                  { attrs: { weight: "bold" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(translationsGroupedByKey.key) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "translator--translations-of-key_content",
                              },
                              _vm._l(
                                translationsGroupedByKey.translations,
                                function (translation, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("text-area-input", {
                                        attrs: {
                                          label: _vm.getLangText(
                                            translation.languageCode
                                          ),
                                          "hide-details": "",
                                          size: "small",
                                        },
                                        model: {
                                          value: translation.value,
                                          callback: function ($$v) {
                                            _vm.$set(translation, "value", $$v)
                                          },
                                          expression: "translation.value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "translator--translations-of-key" },
                      [
                        _vm.translationKey
                          ? _c(
                              "full-anchored-text",
                              { attrs: { weight: "bold" } },
                              [_vm._v(" " + _vm._s(_vm.translationKey) + " ")]
                            )
                          : _vm._e(),
                        _c("full-anchored-text", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "translator_modal.text_is_not_able_to_translate#text"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
            ],
            { slot: "content" }
          ),
          _vm._t(
            "default",
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveTranslation()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save#btn")) + " ")]
              ),
              _c(
                VBtn,
                {
                  staticClass: "ma-0",
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel#btn")) + " ")]
              ),
            ],
            { slot: "actions" }
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }