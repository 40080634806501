import EXHIBITOR_ACTIONS from '@/identity_domain/vuex/exhibitor/exhibitor-actions';
var EXHIBITOR_STORE = {
  state: function state() {
    return {
      isInvitingRegisteredExhibitor: false,
      registeredExhibitorEmail: null,
      exhibitorOrganizationLinks: []
    };
  },
  getters: {
    isInvitingRegisteredExhibitor: function isInvitingRegisteredExhibitor(state) {
      return state.isInvitingRegisteredExhibitor;
    },
    registeredExhibitorEmail: function registeredExhibitorEmail(state) {
      return state.registeredExhibitorEmail;
    },
    exhibitorOrganizationLinks: function exhibitorOrganizationLinks(state) {
      return state.exhibitorOrganizationLinks;
    }
  },
  actions: {
    updateIsInvitingRegisteredExhibitor: function updateIsInvitingRegisteredExhibitor(_ref, isInvitingRegisteredExhibitor) {
      var commit = _ref.commit;
      commit(EXHIBITOR_ACTIONS.updateIsInvitingRegisteredExhibitor, isInvitingRegisteredExhibitor);
    },
    updateRegisteredExhibitorEmail: function updateRegisteredExhibitorEmail(_ref2, registeredExhibitorEmail) {
      var commit = _ref2.commit;
      commit(EXHIBITOR_ACTIONS.updateRegisteredExhibitorEmail, registeredExhibitorEmail);
    },
    updateExhibitorOrganizationLinks: function updateExhibitorOrganizationLinks(_ref3, links) {
      var commit = _ref3.commit;
      commit(EXHIBITOR_ACTIONS.updateExhibitorOrganizationLinks, links);
    }
  },
  mutations: {
    updateIsInvitingRegisteredExhibitor: function updateIsInvitingRegisteredExhibitor(state, isInvitingRegisteredExhibitor) {
      state.isInvitingRegisteredExhibitor = isInvitingRegisteredExhibitor;
    },
    updateRegisteredExhibitorEmail: function updateRegisteredExhibitorEmail(state, registeredExhibitorEmail) {
      state.registeredExhibitorEmail = registeredExhibitorEmail;
    },
    updateExhibitorOrganizationLinks: function updateExhibitorOrganizationLinks(state, links) {
      state.exhibitorOrganizationLinks = links;
    }
  }
};
export default EXHIBITOR_STORE;