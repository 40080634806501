import Vue from 'vue';
import sanitizeHtml from 'sanitize-html';

// https://github.com/punkave/sanitize-html
var defaultOptions = {
  allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'u', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'span'],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    // We don't currently allow img itself by default, but this
    // would make sense if we did. You could add srcset here,
    // and if you do the URL is checked for safety
    img: ['src', 'srcset', 'style'],
    pre: ['class', 'style'],
    div: ['style', 'align'],
    span: ['style'],
    ul: ['style'],
    ol: ['style'],
    li: ['style'],
    u: ['style']
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  allowedSchemesByTag: {
    img: ['data', 'data-*']
  },
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  // allow urls starting with '//'?
  nonTextTags: ['style', 'script'],
  transformTags: {
    pre: sanitizeHtml.simpleTransform('pre', {
      class: 'wrap-text'
    })
  }
};
Vue.prototype.$sanitizeHtml = function (html, customOptions) {
  var sanitizedText = html ? sanitizeHtml(html, customOptions || defaultOptions) : '';
  return sanitizedText;
};