import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
import SupplierAuthentication from '@/supplier_domain/authentications/supplier-authentication';
import SupplierAuthorization from '@/supplier_domain/authorizations/supplier-authorization';
export default [{
  path: '/supplier-dashboard',
  name: 'supplier-dashboard',
  component: function component() {
    return import('@/supplier_domain/supplier_dashboard/components/SupplierDashboard.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-dashboard/tasks',
  component: function component() {
    return import('@/event_management_domain_new/coopax_tasks/task-in-dashboard/components/DashboardAllTaskContainer.vue');
  },
  name: 'supplier-event-tasks',
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-dashboard/all-supplier-events',
  name: 'all-supplier-events',
  component: function component() {
    return import('@/supplier_domain/supplier_dashboard/components/AllSupplierEvents.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-dashboard/all-event-supplier-requests',
  name: 'all-event-supplier-requests',
  component: function component() {
    return import('@/supplier_domain/supplier_dashboard/components/AllSupplierRequests.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/public-supplier-page/:supplierSeoId',
  name: 'public-supplier-page',
  component: function component() {
    return import('@/supplier_domain/public_page/components/SupplierPublicPage.vue');
  },
  props: true
}, {
  path: '/supplier-information',
  name: 'supplier-information',
  component: function component() {
    return import('@/supplier_domain/supplier/components/SupplierContainer.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-services',
  name: 'supplier-services',
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierServicesContainer.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  },
  children: [{
    path: 'food-type',
    name: 'supplier-food-services',
    component: function component() {
      return import('@/supplier_domain/supplier_services/components/SupplierFoodServices.vue');
    },
    props: true
  }, {
    path: 'beverage-type',
    name: 'supplier-beverage-services',
    component: function component() {
      return import('@/supplier_domain/supplier_services/components/SupplierBeverageServices.vue');
    },
    props: true
  }, {
    path: 'rentable-type',
    name: 'supplier-rentable-services',
    component: function component() {
      return import('@/supplier_domain/supplier_services/components/SupplierRentableServices.vue');
    },
    props: true
  }, {
    path: 'human-type',
    name: 'supplier-human-services',
    component: function component() {
      return import('@/supplier_domain/supplier_services/components/SupplierHumanServices.vue');
    },
    props: true
  }, {
    path: 'consumable-type',
    name: 'supplier-consumable-services',
    component: function component() {
      return import('@/supplier_domain/supplier_services/components/SupplierConsumableServices.vue');
    },
    props: true
  }]
}, {
  path: '/supplier-food-services/:serviceId',
  name: 'supplier-food-service',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierFoodService.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-beverage-services/:serviceId',
  name: 'supplier-beverage-service',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierBeverageService.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-rentable-services/:serviceId',
  name: 'supplier-rentable-service',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierRentableService.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-human-services/:serviceId',
  name: 'supplier-human-service',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierHumanService.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-consumable-services/:serviceId',
  name: 'supplier-consumable-service',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_services/components/SupplierConsumableService.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-finance',
  name: 'supplier-finance',
  props: true,
  component: function component() {
    return import('@/financial_domain/supplier_payment_methods/components/SupplierFinance.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-image-repository',
  name: 'supplier-image-repository',
  props: true,
  component: function component() {
    return import('@/core/common/photos/uploader/supplier/SupplierManageImageRepository.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-briefing-forms',
  name: 'supplier-briefing-forms',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_briefing_form/components/SupplierBriefingForms.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-briefing-forms/:briefingFormId',
  name: 'supplier-briefing-form',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_briefing_form/components/SupplierBriefingForm.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-policy',
  name: 'supplier-policy',
  props: true,
  component: function component() {
    return import('@/supplier_domain/supplier_policies/components/SupplierPolicies.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-item-categories',
  name: 'supplier-item-categories',
  component: function component() {
    return import('@/catering_domain/organization_item_categories/components/OrganizationItemCategories.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-item-categories/:itemCategoryId',
  name: 'supplier-item-category',
  component: function component() {
    return import('@/catering_domain/organization_item_categories/components/OrganizationItemCategory.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}, {
  path: '/supplier-paid-invoices',
  name: 'supplier-paid-invoices',
  props: true,
  component: function component() {
    return import('@/financial_domain/customer_paid_invoices/components/SupplierPaidInvoices.vue');
  },
  meta: {
    roles: [OrganizationTypes.SUPPLIER.value],
    authentication: SupplierAuthentication,
    authorization: SupplierAuthorization
  }
}];