var httpStatus = {
  OK: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_BODY: 422,
  INTERNAL_SERVER_ERROR: 500,
  VENUE_OF_EVENT_OFFLINE: 1011,
  VENUE_OF_EVENT_HAS_NO_AVAILABILITY: 1012,
  PRECONDITION_FAILED: 412
};
export default httpStatus;