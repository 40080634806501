var ITEM_NAVIGATION_ACTIONS = {
  updateItemRoutes: 'updateItemRoutes',
  updatePageRequest: 'updatePageRequest',
  updateFetchingListUri: 'updateFetchingListUri',
  updateTotalPage: 'updateTotalPage',
  updateTotalElements: 'updateTotalElements',
  updateBuildingNavigationMethodWrapper: 'updateBuildingNavigationMethodWrapper',
  updatePagePathOfListItem: 'updatePagePathOfListItem',
  updateSaveSuccessfullyAfterSaving: 'updateSaveSuccessfullyAfterSaving',
  updateShouldGoBackToListItemAfterSaving: 'updateShouldGoBackToListItemAfterSaving',
  updateShouldAddAnotherItemAfterSaving: 'updateShouldAddAnotherItemAfterSaving',
  updateShouldGoToNextItemAfterSaving: 'updateShouldGoToNextItemAfterSaving'
};
export default ITEM_NAVIGATION_ACTIONS;