import SEARCH_PAGE_NAVIGATION_ACTIONS from '@/event_booking_domain/search_venues/vuex/search-page-navigation-actions';
var SEARCH_PAGE_NAVIGATION_STORE = {
  state: function state() {
    return {
      isFiltersVisible: true,
      isMapVisible: true
    };
  },
  mutations: {
    updateFiltersVisibility: function updateFiltersVisibility(state, isFiltersVisible) {
      state.isFiltersVisible = isFiltersVisible;
    },
    updateMapVisibility: function updateMapVisibility(state, isMapVisible) {
      state.isMapVisible = isMapVisible;
    }
  },
  getters: {
    isFiltersVisible: function isFiltersVisible(state) {
      return state.isFiltersVisible;
    },
    isMapVisible: function isMapVisible(state) {
      return state.isMapVisible;
    }
  },
  actions: {
    updateFiltersVisibility: function updateFiltersVisibility(_ref, isFiltersVisible) {
      var commit = _ref.commit;
      commit(SEARCH_PAGE_NAVIGATION_ACTIONS.updateFiltersVisibility, isFiltersVisible);
    },
    updateMapVisibility: function updateMapVisibility(_ref2, isMapVisible) {
      var commit = _ref2.commit;
      commit(SEARCH_PAGE_NAVIGATION_ACTIONS.updateMapVisibility, isMapVisible);
    }
  }
};
export default SEARCH_PAGE_NAVIGATION_STORE;