import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        VApp,
        {
          on: {
            wheel: function ($event) {
              return _vm.onScrolled($event)
            },
          },
        },
        [
          _c("loading-overlay", { attrs: { event: "app-busy" } }),
          _c(
            VContent,
            { staticClass: "app-content" },
            [_c("router-view")],
            1
          ),
          _c("app-notification", {
            attrs: {
              visible: _vm.notification.visible,
              type: _vm.notification.type,
              message: _vm.notification.message,
              timeout: _vm.notification.type === "error" ? 0 : 5000,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.notification, "visible", $event)
              },
            },
          }),
          _c("portal-target", { attrs: { name: "top-level" } }),
          _c("authentication-component"),
          _c("release-note-modal", {
            attrs: { visible: _vm.shouldShowReleaseNoteNotificationModal },
          }),
          _c("extend-session-modal", {
            attrs: { visible: _vm.extendSessionModalVisible },
            on: {
              "update:visible": function ($event) {
                _vm.extendSessionModalVisible = $event
              },
            },
          }),
          _c("login-after-token-expired-modal", {
            attrs: { visible: _vm.loginAfterTokenExpiredModalVisible },
            on: {
              "update:visible": function ($event) {
                _vm.loginAfterTokenExpiredModalVisible = $event
              },
            },
          }),
          _c("translator-modal", {
            attrs: {
              visible: _vm.translatorModalVisible,
              "translation-key": _vm.translatingKey,
            },
            on: {
              "update:visible": function ($event) {
                _vm.translatorModalVisible = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }