import UserProfilesAuthentication from '@/crm_domain/users/authentications/user-profiles-authentication';
import UserProfilesAuthorization from '@/crm_domain/users/authorizations/user-profiles-authorization';
import UserInformationAuthorization from '@/crm_domain/users/authorizations/user-information-authorization';
export default [{
  path: '/users/:userProfileId',
  component: function component() {
    return import('@/crm_domain/users/components/UserProfile.vue');
  },
  props: true,
  children: [{
    meta: {},
    path: '',
    redirect: 'information'
  }, {
    meta: {
      authentication: UserProfilesAuthentication,
      authorization: UserInformationAuthorization
    },
    path: 'information',
    name: 'user-profile',
    component: function component() {
      return import('@/crm_domain/users/components/UserInformation.vue');
    }
  }, {
    meta: {
      authentication: UserProfilesAuthentication,
      authorization: UserInformationAuthorization
    },
    path: 'organization-admin-manage-user-profile',
    name: 'organization-admin-manage-user-profile',
    component: function component() {
      return import('@/crm_domain/organization/organization_admin_manage_users/OrganizationAdminUserInformationManagement.vue');
    }
  }, {
    meta: {
      authentication: UserProfilesAuthentication,
      authorization: UserInformationAuthorization
    },
    path: 'e-p-organization-admin-manage-user-profile',
    name: 'e-p-organization-admin-manage-user-profile',
    component: function component() {
      return import('@/crm_domain/organization/organization_admin_manage_users/EPOrganizationAdminUserInformationManagement.vue');
    }
  }, {
    meta: {
      authentication: UserProfilesAuthentication,
      authorization: UserProfilesAuthorization
    },
    name: 'user-organizations',
    path: 'organizations',
    component: function component() {
      return import('@/crm_domain/users/components/UserOrganization.vue');
    }
  }, {
    meta: {
      authentication: UserProfilesAuthentication,
      authorization: UserProfilesAuthorization
    },
    name: 'user-settings',
    path: 'settings',
    component: function component() {
      return import('@/crm_domain/users/components/UserSettings.vue');
    }
  }]
}];