import VENUES_TO_COMPARE_ACTIONS from '@/event_booking_domain/venue_compare/vuex/venues-to-compare-actions';
var VENUES_TO_COMPARE_STORE = {
  state: function state() {
    return {
      comparingVenues: []
    };
  },
  mutations: {
    updateComparingVenues: function updateComparingVenues(state, ids) {
      state.comparingVenues = ids;
    },
    clearComparingVenues: function clearComparingVenues(state) {
      state.comparingVenues = [];
    }
  },
  getters: {
    comparingVenues: function comparingVenues(state) {
      return state.comparingVenues;
    }
  },
  actions: {
    updateComparingVenues: function updateComparingVenues(_ref, ids) {
      var commit = _ref.commit;
      commit(VENUES_TO_COMPARE_ACTIONS.updateComparingVenues, ids);
    },
    clearComparingVenues: function clearComparingVenues(_ref2) {
      var commit = _ref2.commit;
      commit(VENUES_TO_COMPARE_ACTIONS.clearComparingVenues);
    }
  }
};
export default VENUES_TO_COMPARE_STORE;