import BUSINESS_CLIENT_ACTIONS from '@/identity_domain/vuex/business_client/business-client-actions';
var BUSINESS_CLIENT_STORE = {
  state: function state() {
    return {
      businessClientOrganizationLinks: []
    };
  },
  getters: {
    businessClientOrganizationLinks: function businessClientOrganizationLinks(state) {
      return state.businessClientOrganizationLinks;
    }
  },
  actions: {
    updateBusinessClientOrganizationLinks: function updateBusinessClientOrganizationLinks(_ref, businessClientOrganizationLinks) {
      var commit = _ref.commit;
      commit(BUSINESS_CLIENT_ACTIONS.updateBusinessClientOrganizationLinks, businessClientOrganizationLinks);
    }
  },
  mutations: {
    updateBusinessClientOrganizationLinks: function updateBusinessClientOrganizationLinks(state, businessClientOrganizationLinks) {
      state.businessClientOrganizationLinks = businessClientOrganizationLinks;
    }
  }
};
export default BUSINESS_CLIENT_STORE;