import EVENT_PLANNER_ACTIONS from '@/identity_domain/vuex/event_planner/event-planner-actions';
var EVENT_PLANNER_STORE = {
  state: function state() {
    return {
      eventPlannerOrganizationLinks: [],
      eventPlannerId: null,
      isEventPlannerOnline: false,
      canMakeEventPlannerOnline: false,
      shouldGetEventPlannerInfo: false
    };
  },
  getters: {
    eventPlannerId: function eventPlannerId(state) {
      return state.eventPlannerId;
    },
    isEventPlannerOnline: function isEventPlannerOnline(state) {
      return state.isEventPlannerOnline;
    },
    canMakeEventPlannerOnline: function canMakeEventPlannerOnline(state) {
      return state.canMakeEventPlannerOnline;
    },
    shouldGetEventPlannerInfo: function shouldGetEventPlannerInfo(state) {
      return state.shouldGetEventPlannerInfo;
    },
    eventPlannerOrganizationLinks: function eventPlannerOrganizationLinks(state) {
      return state.eventPlannerOrganizationLinks;
    }
  },
  actions: {
    updateEventPlannerId: function updateEventPlannerId(_ref, eventPlannerId) {
      var commit = _ref.commit;
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerId, eventPlannerId);
    },
    updateEventPlannerStatus: function updateEventPlannerStatus(_ref2, isOnline) {
      var commit = _ref2.commit;
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerStatus, isOnline);
    },
    updateCanMakeEventPlannerOnline: function updateCanMakeEventPlannerOnline(_ref3, canMakeEventPlannerOnline) {
      var commit = _ref3.commit;
      commit(EVENT_PLANNER_ACTIONS.updateCanMakeEventPlannerOnline, canMakeEventPlannerOnline);
    },
    updateShouldGetEventPlannerInfo: function updateShouldGetEventPlannerInfo(_ref4, shouldGetEventPlannerInfo) {
      var commit = _ref4.commit;
      commit(EVENT_PLANNER_ACTIONS.updateShouldGetEventPlannerInfo, shouldGetEventPlannerInfo);
    },
    updateEventPlannerOrganizationLinks: function updateEventPlannerOrganizationLinks(_ref5, eventPlannerOrganizationLinks) {
      var commit = _ref5.commit;
      commit(EVENT_PLANNER_ACTIONS.updateEventPlannerOrganizationLinks, eventPlannerOrganizationLinks);
    }
  },
  mutations: {
    updateEventPlannerId: function updateEventPlannerId(state, eventPlannerId) {
      state.eventPlannerId = eventPlannerId;
    },
    updateEventPlannerStatus: function updateEventPlannerStatus(state, isOnline) {
      state.isEventPlannerOnline = isOnline;
    },
    updateCanMakeEventPlannerOnline: function updateCanMakeEventPlannerOnline(state, canMakeEventPlannerOnline) {
      state.canMakeEventPlannerOnline = canMakeEventPlannerOnline;
    },
    updateShouldGetEventPlannerInfo: function updateShouldGetEventPlannerInfo(state, shouldGetEventPlannerInfo) {
      state.shouldGetEventPlannerInfo = shouldGetEventPlannerInfo;
    },
    updateEventPlannerOrganizationLinks: function updateEventPlannerOrganizationLinks(state, eventPlannerOrganizationLinks) {
      state.eventPlannerOrganizationLinks = eventPlannerOrganizationLinks;
    }
  }
};
export default EVENT_PLANNER_STORE;