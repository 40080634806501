import SEARCH_PAGE_CRITERIA_ACTIONS from '@/event_booking_domain/search_venues/vuex/search-page-criteria-actions';
import DefaultSearchCriteria from '@/event_booking_domain/search_venues/constants/default-search-criteria';
var SEARCH_PAGE_CRITERIA_STORE = {
  state: function state() {
    return {
      searchCriteria: DefaultSearchCriteria,
      searchPagination: {
        page: 1,
        rowsPerPage: 24
      },
      nextSearchURI: '',
      prevSearchURI: '',
      venueIdListForNavigate: []
    };
  },
  mutations: {
    updateSearchCriteria: function updateSearchCriteria(state, searchCriteria) {
      state.searchCriteria = searchCriteria;
    },
    clearSearchCriteria: function clearSearchCriteria(state) {
      state.searchCriteria = DefaultSearchCriteria;
      state.searchPagination = {
        page: 1,
        rowsPerPage: 24
      };
    },
    updateSearchPagination: function updateSearchPagination(state, page) {
      state.searchPagination.page = page;
    },
    updateNextSearchURI: function updateNextSearchURI(state, nextLink) {
      state.nextSearchURI = nextLink;
    },
    updatePrevSearchURI: function updatePrevSearchURI(state, prevLink) {
      state.prevSearchURI = prevLink;
    },
    updateVenueIdListForNavigate: function updateVenueIdListForNavigate(state, venueIds) {
      state.venueIdListForNavigate = venueIds;
    }
  },
  getters: {
    searchCriteria: function searchCriteria(state) {
      return state.searchCriteria;
    },
    searchPagination: function searchPagination(state) {
      return state.searchPagination;
    },
    nextSearchURI: function nextSearchURI(state) {
      return state.nextSearchURI;
    },
    prevSearchURI: function prevSearchURI(state) {
      return state.prevSearchURI;
    },
    venueIdListForNavigate: function venueIdListForNavigate(state) {
      return state.venueIdListForNavigate;
    }
  },
  actions: {
    updateSearchCriteria: function updateSearchCriteria(_ref, searchCriteria) {
      var commit = _ref.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateSearchCriteria, searchCriteria);
    },
    clearSearchCriteria: function clearSearchCriteria(_ref2) {
      var commit = _ref2.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.clearSearchCriteria);
    },
    updateSearchPagination: function updateSearchPagination(_ref3, page) {
      var commit = _ref3.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateSearchPagination, page);
    },
    updateNextSearchURI: function updateNextSearchURI(_ref4, nextLink) {
      var commit = _ref4.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateNextSearchURI, nextLink);
    },
    updatePrevSearchURI: function updatePrevSearchURI(_ref5, prevLink) {
      var commit = _ref5.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updatePrevSearchURI, prevLink);
    },
    updateVenueIdListForNavigate: function updateVenueIdListForNavigate(_ref6, venueIds) {
      var commit = _ref6.commit;
      commit(SEARCH_PAGE_CRITERIA_ACTIONS.updateVenueIdListForNavigate, venueIds);
    }
  }
};
export default SEARCH_PAGE_CRITERIA_STORE;