import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import values from 'lodash/values';
import head from 'lodash/head';
import isFunction from 'lodash/isFunction';
export default {
  EN: {
    get text() {
      return 'language.english#vary';
    },
    value: 'en',
    languageCodeAbbr: 'en',
    active: true
  },
  NL: {
    get text() {
      return 'language.dutch#vary';
    },
    value: 'nl',
    languageCodeAbbr: 'nl',
    active: true
  },
  BR: {
    get text() {
      return 'language.brazilian#vary';
    },
    value: 'br',
    languageCodeAbbr: 'br',
    active: true
  },
  DE: {
    get text() {
      return 'language.german#vary';
    },
    value: 'de',
    languageCodeAbbr: 'de',
    active: true
  },
  ES: {
    get text() {
      return 'language.spanish#vary';
    },
    value: 'es',
    languageCodeAbbr: 'es',
    active: true
  },
  of: function of(languageCode) {
    return head(values(this).filter(function (lang) {
      return lang.value === languageCode;
    }));
  },
  getList: function getList(i18n) {
    return values(this).filter(function (language) {
      return !isFunction(language);
    }).map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        get text() {
          return i18n.t(item.text);
        }
      });
    });
  },
  getOriginalList: function getOriginalList() {
    return values(this).filter(function (language) {
      return !isFunction(language);
    });
  }
};