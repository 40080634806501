import VenueAvailabilities from '@/event_booking_domain/search_venues/constants/venue-availabilities';
var DefaultSearchCriteria = {
  selectedVenueAvailability: VenueAvailabilities.ALL_VENUES_BY_HIERARCHY_ORDER.value,
  city: null,
  venueName: null,
  cityLat: null,
  cityLng: null,
  distance: 20,
  selectedActivity: null,
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  minPrice: null,
  maxPrice: null,
  attendees: 0,
  basicItemIds: [],
  hasOvernightStay: null,
  hasCoffeeTea: false,
  propertySections: [],
  reviews: {
    average: 0,
    informationOnWebpage: 0,
    price: 0,
    foodAndBeverage: 0,
    hygiene: 0,
    communication: 0,
    service: 0,
    personnel: 0
  },
  purposeId: null,
  onlyShowFavoriteVenues: false,
  onlyShowSupportWholeVenueRentalVenues: null,
  adminPaymentMethodIds: []
};
export default DefaultSearchCriteria;