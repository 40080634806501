import PERSONAL_CLIENT_ACTIONS from '@/identity_domain/vuex/personal_client/personal-client-actions';
var PERSONAL_CLIENT_STORE = {
  state: function state() {
    return {
      personalClientOrganizationLinks: []
    };
  },
  getters: {
    personalClientOrganizationLinks: function personalClientOrganizationLinks(state) {
      return state.personalClientOrganizationLinks;
    }
  },
  actions: {
    updatePersonalClientOrganizationLinks: function updatePersonalClientOrganizationLinks(_ref, personalClientOrganizationLinks) {
      var commit = _ref.commit;
      commit(PERSONAL_CLIENT_ACTIONS.updatePersonalClientOrganizationLinks, personalClientOrganizationLinks);
    }
  },
  mutations: {
    updatePersonalClientOrganizationLinks: function updatePersonalClientOrganizationLinks(state, personalClientOrganizationLinks) {
      state.personalClientOrganizationLinks = personalClientOrganizationLinks;
    }
  }
};
export default PERSONAL_CLIENT_STORE;