var Message = function Message() {
  return import('@/communication_domain/organization_message/component/MessageView.vue');
};
var MessageInbox = function MessageInbox() {
  return import('@/communication_domain/organization_message/component/MessageContainer.vue');
};
var MessageDetails = function MessageDetails() {
  return import('@/communication_domain/organization_message/component/MessagesDetails.vue');
};
var OrganizationMessages = function OrganizationMessages() {
  return import('@/communication_domain/organization_message/component/OrganizationMessages.vue');
};
import MessagesDetailsAuthorization from '@/communication_domain/organization_message/authorizations/messages-details-authorization';
import MessageAuthentication from '@/communication_domain/organization_message/authentications/message-authentication';
import OrganizationMessageConfigAuthorization from '@/communication_domain/organization_message/authorizations/organization-message-config-authorization';
export default [{
  path: '/messages/:organizationId',
  name: 'messages',
  component: Message,
  meta: {
    authentication: MessageAuthentication
  },
  props: true,
  children: [{
    name: 'message',
    meta: {
      authentication: MessageAuthentication
    },
    path: '',
    component: MessageInbox
  }, {
    name: 'message',
    meta: {
      authentication: MessageAuthentication
    },
    path: 'messages',
    component: MessageInbox
  }, {
    name: 'message-detail',
    meta: {
      authentication: MessageAuthentication,
      authorization: MessagesDetailsAuthorization
    },
    path: 'messages/:messageId',
    component: MessageDetails,
    props: true
  }, {
    path: 'message-settings',
    name: 'messages-setting',
    component: OrganizationMessages,
    meta: {
      authentication: MessageAuthentication,
      authorization: OrganizationMessageConfigAuthorization
    },
    props: true
  }]
}];