import VENUE_MANAGER_ACTIONS from '@/identity_domain/vuex/venue_manager/venue-manager-actions';
var VENUE_MANAGER_STORE = {
  state: function state() {
    return {
      venueManagerOrganizationLinks: []
    };
  },
  getters: {
    venueManagerOrganizationLinks: function venueManagerOrganizationLinks(state) {
      return state.venueManagerOrganizationLinks;
    }
  },
  actions: {
    updateVenueManagerOrganizationLinks: function updateVenueManagerOrganizationLinks(_ref, venueManagerOrganizationLinks) {
      var commit = _ref.commit;
      commit(VENUE_MANAGER_ACTIONS.updateVenueManagerOrganizationLinks, venueManagerOrganizationLinks);
    }
  },
  mutations: {
    updateVenueManagerOrganizationLinks: function updateVenueManagerOrganizationLinks(state, venueManagerOrganizationLinks) {
      state.venueManagerOrganizationLinks = venueManagerOrganizationLinks;
    }
  }
};
export default VENUE_MANAGER_STORE;