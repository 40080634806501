import values from 'lodash/values';
export default {
  getFirstBrowserLanguage: function getFirstBrowserLanguage(languages) {
    var nav = window.navigator;
    var browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
    var language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      language = nav.languages.find(function (lang) {
        return lang && lang.length > 0;
      });
    }

    // support for other well known properties in browsers
    browserLanguagePropertyKeys.forEach(function (propertyKey) {
      if (nav[propertyKey] && nav[propertyKey].length > 0) {
        language = nav[propertyKey];
      }
    });
    var isLanguageSupported = values(languages).some(function (lang) {
      return lang === language;
    });
    return isLanguageSupported ? language : 'en-US';
  },
  getPreferredLanguage: function getPreferredLanguage(languages) {
    var preferredBrowserLanguage = this.getFirstBrowserLanguage(languages);
    var accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return localStorage.getItem('locale') || preferredBrowserLanguage;
    }
    return preferredBrowserLanguage;
  }
};