import ITEM_NAVIGATION_ACTIONS from '@/core/item_navigation/store/item-navigation-actions';
var ITEM_NAVIGATION_STORE = {
  state: function state() {
    return {
      /**
       * list language of admin that is active
       */
      itemRoutes: [],
      /**
       * API uri to fetch list data
       */
      fetchingListUri: '',
      /**
       * Filter, sort and pagination information
       */
      pageRequest: {},
      /**
       * Total page of a table
       */
      totalPage: 1,
      /**
       * Total items in the table
       */
      totalElements: 1,
      /**
       * the method that is use to build the 'editing item route' from item data
       */
      buildingNavigationMethodWrapper: {},
      /**
       * path to the item list
       */
      pagePathOfListItem: '',
      /**
       * we use "saveSuccessfully" to control handling actions after saving
       */
      saveSuccessfully: false,
      /**
       * Should go to next item after saving
       */
      shouldGoToNextItemAfterSaving: false,
      /**
       * Should add another item after saving
       */
      shouldAddAnotherItemAfterSaving: false,
      /**
       * Should go back to list item after saving
       */
      shouldGoBackToListItemAfterSaving: false
    };
  },
  mutations: {
    updateItemRoutes: function updateItemRoutes(state, itemRoutes) {
      state.itemRoutes = itemRoutes;
    },
    updateFetchingListUri: function updateFetchingListUri(state, fetchingListUri) {
      state.fetchingListUri = fetchingListUri;
    },
    updatePageRequest: function updatePageRequest(state, pageRequest) {
      state.pageRequest = pageRequest;
    },
    updateTotalPage: function updateTotalPage(state, totalPage) {
      state.totalPage = totalPage;
    },
    updateBuildingNavigationMethodWrapper: function updateBuildingNavigationMethodWrapper(state, _updateBuildingNavigationMethodWrapper) {
      state.buildingNavigationMethodWrapper = _updateBuildingNavigationMethodWrapper;
    },
    updatePagePathOfListItem: function updatePagePathOfListItem(state, pagePathOfListItem) {
      state.pagePathOfListItem = pagePathOfListItem;
    },
    updateTotalElements: function updateTotalElements(state, totalElements) {
      state.totalElements = totalElements;
    },
    updateSaveSuccessfullyAfterSaving: function updateSaveSuccessfullyAfterSaving(state, saveSuccessfully) {
      state.saveSuccessfully = saveSuccessfully;
    },
    updateShouldGoBackToListItemAfterSaving: function updateShouldGoBackToListItemAfterSaving(state, shouldGoBackToListItemAfterSaving) {
      state.shouldGoBackToListItemAfterSaving = shouldGoBackToListItemAfterSaving;
    },
    updateShouldAddAnotherItemAfterSaving: function updateShouldAddAnotherItemAfterSaving(state, shouldAddAnotherItemAfterSaving) {
      state.shouldAddAnotherItemAfterSaving = shouldAddAnotherItemAfterSaving;
    },
    updateShouldGoToNextItemAfterSaving: function updateShouldGoToNextItemAfterSaving(state, shouldGoToNextItemAfterSaving) {
      state.shouldGoToNextItemAfterSaving = shouldGoToNextItemAfterSaving;
    }
  },
  getters: {
    getItemRoutes: function getItemRoutes(state) {
      return state.itemRoutes;
    },
    getFetchingListUri: function getFetchingListUri(state) {
      return state.fetchingListUri;
    },
    getPageRequest: function getPageRequest(state) {
      return state.pageRequest;
    },
    getTotalPage: function getTotalPage(state) {
      return state.totalPage;
    },
    getBuildingNavigationMethodWrapper: function getBuildingNavigationMethodWrapper(state) {
      return state.buildingNavigationMethodWrapper;
    },
    getPagePathOfListItem: function getPagePathOfListItem(state) {
      return state.pagePathOfListItem;
    },
    getTotalElements: function getTotalElements(state) {
      return state.totalElements;
    },
    getSaveSuccessfully: function getSaveSuccessfully(state) {
      return state.saveSuccessfully;
    },
    shouldGoToNextItemAfterSaving: function shouldGoToNextItemAfterSaving(state) {
      return state.shouldGoToNextItemAfterSaving;
    },
    shouldAddAnotherItemAfterSaving: function shouldAddAnotherItemAfterSaving(state) {
      return state.shouldAddAnotherItemAfterSaving;
    },
    shouldGoBackToListItemAfterSaving: function shouldGoBackToListItemAfterSaving(state) {
      return state.shouldGoBackToListItemAfterSaving;
    }
  },
  actions: {
    /**
     * update all item routes of current list
     * @param commit
     * @param itemRoutes
     */
    updateItemRoutes: function updateItemRoutes(_ref, itemRoutes) {
      var commit = _ref.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateItemRoutes, itemRoutes);
    },
    updateFetchingListUri: function updateFetchingListUri(_ref2, fetchingListUri) {
      var commit = _ref2.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateFetchingListUri, fetchingListUri);
    },
    updatePageRequest: function updatePageRequest(_ref3, pageRequest) {
      var commit = _ref3.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updatePageRequest, pageRequest);
    },
    updateTotalPage: function updateTotalPage(_ref4, totalPage) {
      var commit = _ref4.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateTotalPage, totalPage);
    },
    updateBuildingNavigationMethodWrapper: function updateBuildingNavigationMethodWrapper(_ref5, buildingNavigationMethodWrapper) {
      var commit = _ref5.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateBuildingNavigationMethodWrapper, buildingNavigationMethodWrapper);
    },
    updatePagePathOfListItem: function updatePagePathOfListItem(_ref6, pagePathOfListItem) {
      var commit = _ref6.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updatePagePathOfListItem, pagePathOfListItem);
    },
    updateTotalElements: function updateTotalElements(_ref7, totalElements) {
      var commit = _ref7.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateTotalElements, totalElements);
    },
    updateSaveSuccessfullyAfterSaving: function updateSaveSuccessfullyAfterSaving(_ref8, saveSuccessfully) {
      var commit = _ref8.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateSaveSuccessfullyAfterSaving, saveSuccessfully);
    },
    updateShouldGoBackToListItemAfterSaving: function updateShouldGoBackToListItemAfterSaving(_ref9, shouldGoBackToListItemAfterSaving) {
      var commit = _ref9.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldGoBackToListItemAfterSaving, shouldGoBackToListItemAfterSaving);
    },
    updateShouldAddAnotherItemAfterSaving: function updateShouldAddAnotherItemAfterSaving(_ref10, shouldAddAnotherItemAfterSaving) {
      var commit = _ref10.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldAddAnotherItemAfterSaving, shouldAddAnotherItemAfterSaving);
    },
    updateShouldGoToNextItemAfterSaving: function updateShouldGoToNextItemAfterSaving(_ref11, shouldGoToNextItemAfterSaving) {
      var commit = _ref11.commit;
      commit(ITEM_NAVIGATION_ACTIONS.updateShouldGoToNextItemAfterSaving, shouldGoToNextItemAfterSaving);
    }
  }
};
export default ITEM_NAVIGATION_STORE;