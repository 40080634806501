import GlobalAuthentication from '@/identity_domain/login/authentications/global-authentication';
var OrganizationProfileAuthentication = {
  // eslint-disable-next-line no-unused-vars
  authenticate: function authenticate(_ref) {
    var to = _ref.to,
      from = _ref.from,
      next = _ref.next;
    GlobalAuthentication.authenticate({
      to: to,
      from: from,
      next: next
    });
  }
};
export default OrganizationProfileAuthentication;