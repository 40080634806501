import ADMIN_ACTIONS from '@/identity_domain/vuex/admin/admin-actions';
var ADMIN_STORE = {
  state: function state() {
    return {
      adminLinks: []
    };
  },
  getters: {
    adminLinks: function adminLinks(state) {
      return state.adminLinks;
    }
  },
  actions: {
    updateAdminLinks: function updateAdminLinks(_ref, adminLinks) {
      var commit = _ref.commit;
      commit(ADMIN_ACTIONS.updateAdminLinks, adminLinks);
    }
  },
  mutations: {
    updateAdminLinks: function updateAdminLinks(state, adminLinks) {
      state.adminLinks = adminLinks;
    }
  }
};
export default ADMIN_STORE;