import Languages from '@/crm_domain/languages/constants/languages-const';
import head from 'lodash/head';
var MultiLanguageMethodObject = {
  getTextFromMultiLanguageData: function getTextFromMultiLanguageData(texts, langCode) {
    if (!texts || texts.length === 0) {
      return '';
    }
    var multiLanguageData = head(texts.filter(function (text) {
      return text.languageCode === langCode;
    }));

    // return correct data of current language
    // If not present, return english data (always exist)
    if (multiLanguageData) {
      return multiLanguageData.data;
    }
    var englishData = head(texts.filter(function (text) {
      return text.languageCode === Languages.EN.value;
    }));
    if (englishData) {
      return englishData.data;
    }
    return '';
  }
};
export default MultiLanguageMethodObject;