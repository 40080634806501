export default {
  CALENDAR: {
    area: {
      width: 40,
      height: 30
    },
    ebAvatar: {
      width: 48,
      height: 48
    }
  },
  COMPARE_VENUE: {
    desktop: {
      width: 300,
      height: 200
    },
    mobile: {
      width: window.screen.availWidth,
      height: 200
    }
  },
  LOCATION_PROFILE: {
    carousel: {
      width: 390,
      height: 200
    },
    header: {
      width: window.screen.availWidth,
      height: window.screen.availHeight
    },
    area: {
      width: 100,
      height: 50
    },
    setting: {
      width: 49,
      height: 61
    }
  },
  SEARCH: {
    locationPhoto: {
      width: 300,
      height: 125
    },
    smLocationPhoto: {
      width: window.screen.availWidth,
      height: 300
    }
  },
  MAP: {
    locationPhoto: {
      width: 220,
      height: 150
    }
  },
  ADMIN: {
    setting: {
      width: 70,
      height: 70
    },
    adminAvatar: {
      width: 200,
      height: 200
    }
  },
  LOCATION: {
    item: {
      width: 100,
      height: 50
    },
    list: {
      width: 105,
      height: 60
    },
    imgList: {
      width: 455,
      height: 200
    }
  },
  DASHBOARD: {
    upcomingLocations: {
      width: 105,
      height: 60
    },
    allEventLocations: {
      width: 50,
      height: 50
    },
    carousel: {
      width: 395,
      height: 200
    },
    taskOwner: {
      width: 50,
      height: 50
    }
  },
  DIALOGS: {
    areaType: {
      width: 700,
      height: 350
    },
    areaTypePlacement: {
      width: 50,
      height: 50
    },
    area: {
      width: 50,
      height: 50
    },
    locationsInCreateEvent: {
      width: 172,
      height: 150
    },
    organizationSelection: {
      width: 40,
      height: 40
    }
  },
  EVENT: {
    editDayScheduleItem: {
      width: 268,
      height: 200
    },
    itemInList: {
      width: 50,
      height: 50
    },
    areaList: {
      width: 50,
      height: 50
    },
    areaSelected: {
      width: 100,
      height: 100
    },
    placement: {
      width: 50,
      height: 50
    },
    inviteEventOrgaAvatar: {
      width: 194,
      height: 200
    },
    invoiceLogo: {
      width: 80,
      height: 80
    },
    location: {
      width: 70,
      height: 60
    },
    locationLazy: {
      width: 5,
      height: 5
    },
    eventOrganization: {
      width: 70,
      height: 60
    }
  },
  MESSAGES: {
    inboxAvatar: {
      width: 64,
      height: 64
    },
    sentBoxAvatar: {
      width: 31,
      height: 31
    },
    selectOrgaAvatar: {
      width: 40,
      height: 40
    },
    chipAvatar: {
      width: 32,
      height: 32
    },
    detailAvatar: {
      width: 48,
      height: 48
    },
    menuMessages: {
      width: 40,
      height: 40
    }
  },
  ORGA_PROFILE: {
    location: {
      width: 200,
      height: 200
    },
    avatar: {
      width: 180,
      height: 180
    },
    userAvatar: {
      width: 200,
      height: 200
    }
  },
  LAYOUT: {
    HEADER: {
      avatar: {
        width: 48,
        height: 48
      }
    }
  }
};