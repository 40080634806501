import EPS_TO_COMPARE_ACTIONS from '@/event_booking_domain/event_planner_compare/vuex/eps-to-compare-actions';
var EPS_TO_COMPARE_STORE = {
  state: function state() {
    return {
      comparingEventPlannerIds: []
    };
  },
  mutations: {
    updateComparingEventPlannerIds: function updateComparingEventPlannerIds(state, ids) {
      state.comparingEventPlannerIds = ids;
    },
    clearComparingEventPlannerIds: function clearComparingEventPlannerIds(state) {
      state.comparingEventPlannerIds = [];
    }
  },
  getters: {
    comparingEventPlannerIds: function comparingEventPlannerIds(state) {
      return state.comparingEventPlannerIds;
    }
  },
  actions: {
    updateComparingEventPlannerIds: function updateComparingEventPlannerIds(_ref, ids) {
      var commit = _ref.commit;
      commit(EPS_TO_COMPARE_ACTIONS.updateComparingEventPlannerIds, ids);
    },
    clearComparingEventPlannerIds: function clearComparingEventPlannerIds(_ref2) {
      var commit = _ref2.commit;
      commit(EPS_TO_COMPARE_ACTIONS.clearComparingEventPlannerIds);
    }
  }
};
export default EPS_TO_COMPARE_STORE;