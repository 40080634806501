import OrganizationProfileAuthorization from '@/crm_domain/organization/authorizations/organization-profile-authorization';
import OrganizationProfileAuthentication from '@/crm_domain/organization/authentications/organization-profile-authentication';
export default [{
  path: '/organizations/:organizationId',
  component: function component() {
    return import('@/crm_domain/organization/components/OrganizationProfile.vue');
  },
  props: true,
  meta: {
    roles: ['ORGANIZATION_ADMIN']
  },
  children: [{
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: '',
    redirect: 'information'
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    name: 'organization-profile-information',
    path: 'information',
    component: function component() {
      return import('@/crm_domain/organization/components/OrganizationInformation.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'users',
    name: 'organization-profile-users',
    component: function component() {
      return import('@/crm_domain/organization/components/OrganizationUsers.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'settings',
    name: 'organization-profile-settings',
    component: function component() {
      return import('@/crm_domain/organization/components/OrganizationSettings.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'messages',
    name: 'organization-profile-messages',
    component: function component() {
      return import('@/communication_domain/organization_message/component/OrganizationMessages.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'turnover-accounts',
    name: 'organization-profile-turnover-account',
    component: function component() {
      return import('@/financial_domain/organization_turnover_account/components/OrganizationTurnoverAccounts.vue');
    },
    props: true
  }, {
    meta: {
      authentication: OrganizationProfileAuthentication,
      authorization: OrganizationProfileAuthorization
    },
    path: 'bank-account',
    name: 'organization-bank-account',
    component: function component() {
      return import('@/financial_domain/bank_accounts/OrganizationBankAccountContainer.vue');
    },
    props: true
  }]
}];