import Vue from 'vue';
var TextInput = function TextInput() {
  return import('@/core/common/form-inputs/TextInput.vue');
};
var MultiLanguageTextInput = function MultiLanguageTextInput() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageTextInput.vue');
};
var MultiLanguageSelectInput = function MultiLanguageSelectInput() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageSelectInput.vue');
};
var MultiLanguageTextArea = function MultiLanguageTextArea() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageTextArea.vue');
};
var MultiLanguageRichText = function MultiLanguageRichText() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageRichText.vue');
};
var MultiLanguageWrapper = function MultiLanguageWrapper() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageWrapper.vue');
};
var MultiLanguageSelectMultipleInputShortDescription = function MultiLanguageSelectMultipleInputShortDescription() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageSelectMultipleInputShortDescription.vue');
};
var MultiLanguageLabelTextInput = function MultiLanguageLabelTextInput() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageLabelTextInput.vue');
};
var PriceInput = function PriceInput() {
  return import('@/core/common/form-inputs/PriceInput.vue');
};
var TextAreaInput = function TextAreaInput() {
  return import('@/core/common/form-inputs/TextAreaInput.vue');
};
var AnchoredText = function AnchoredText() {
  return import('@/core/common/labels/AnchoredText.vue');
};
var MultiRowAnchoredTextWithTooltip = function MultiRowAnchoredTextWithTooltip() {
  return import('@/core/common/labels/MultiRowAnchoredTextWithTooltip.vue');
};
var FullAnchoredText = function FullAnchoredText() {
  return import('@/core/common/labels/FullAnchoredText.vue');
};
var SelectInput = function SelectInput() {
  return import('@/core/common/form-inputs/SelectInput.vue');
};
var AutocompleteInput = function AutocompleteInput() {
  return import('@/core/common/form-inputs/AutocompleteInput.vue');
};
var PurposeMultiLanguageAutocompleteInput = function PurposeMultiLanguageAutocompleteInput() {
  return import('@/core/common/form-inputs/multi-language-input/PurposeMultiLanguageAutocompleteInput.vue');
};
var MultiLanguageAutocompleteInput = function MultiLanguageAutocompleteInput() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageAutocompleteInput.vue');
};
var CheckboxInput = function CheckboxInput() {
  return import('@/core/common/form-inputs/CheckboxInput.vue');
};
var RadioGroupInput = function RadioGroupInput() {
  return import('@/core/common/form-inputs/RadioGroupInput.vue');
};
var SwitchInput = function SwitchInput() {
  return import('@/core/common/form-inputs/SwitchInput.vue');
};
var TwoLabelSwitch = function TwoLabelSwitch() {
  return import('@/core/common/form-inputs/TwoLabelSwitch.vue');
};
var LanguageSelector = function LanguageSelector() {
  return import('@/core/common/form-inputs/LanguageSelector.vue');
};
var VButton = function VButton() {
  return import('@/core/common/buttons/VButton.vue');
};
var SmallButton = function SmallButton() {
  return import('@/core/common/buttons/SmallButton.vue');
};
var TextButton = function TextButton() {
  return import('@/core/common/buttons/TextButton.vue');
};
var ButtonIcon = function ButtonIcon() {
  return import('@/core/common/buttons/ButtonIcon.vue');
};
var ButtonIconWithBackground = function ButtonIconWithBackground() {
  return import('@/core/common/buttons/ButtonIconWithBackground.vue');
};
var ButtonIconToolTip = function ButtonIconToolTip() {
  return import('@/core/common/buttons/ButtonIconToolTip.vue');
};
var InListWarningComponent = function InListWarningComponent() {
  return import('@/core/common/InListWarningComponent.vue');
};
var Modal = function Modal() {
  return import('@/core/common/dialogs/Modal.vue');
};
var ModalNotFullScreenInMobile = function ModalNotFullScreenInMobile() {
  return import('@/core/common/dialogs/ModalNotFullScreenInMobile.vue');
};
var CardInModal = function CardInModal() {
  return import('@/core/common/dialogs/CardInModal.vue');
};
var ResponsiveImage = function ResponsiveImage() {
  return import('@/core/common/ResponsiveImage.vue');
};
var CroppedResponsiveImage = function CroppedResponsiveImage() {
  return import('@/core/common/CroppedResponsiveImage.vue');
};
var AnchoredHeading = function AnchoredHeading() {
  return import('@/core/common/labels/AnchoredHeading.vue');
};
var ReadMoreText = function ReadMoreText() {
  return import('@/core/common/ReadMoreText.vue');
};
var DataTableComponent = function DataTableComponent() {
  return import('@/core/common/data-table/DataTableComponent.vue');
};
var AnchoredCarousel = function AnchoredCarousel() {
  return import('@/core/common/carousel/AnchoredCarousel.vue');
};
var PhotoInList = function PhotoInList() {
  return import('@/core/common/photos/PhotoInList.vue');
};
var IconComponent = function IconComponent() {
  return import('@/core/common/icons/IconComponent.vue');
};
var IconToolTipComponent = function IconToolTipComponent() {
  return import('@/core/common/icons/IconToolTipComponent.vue');
};
var MultiLanguageIconTooltipComponent = function MultiLanguageIconTooltipComponent() {
  return import('@/core/common/icons/MultiLanguageIconTooltipComponent.vue');
};
var NoDataInList = function NoDataInList() {
  return import('@/core/common/NoDataInList.vue');
};
var ButtonInMenu = function ButtonInMenu() {
  return import('@/core/common/buttons/ButtonInMenu.vue');
};
var AddressLabel = function AddressLabel() {
  return import('@/core/common/AddressLabel.vue');
};
var SelectMultipleInputShortDescription = function SelectMultipleInputShortDescription() {
  return import('@/core/common/form-inputs/SelectMultipleInputShortDescription.vue');
};
var MultiLanguageLabel = function MultiLanguageLabel() {
  return import('@/core/common/labels/MultiLanguageLabel.vue');
};
var TextButtonIconTooltip = function TextButtonIconTooltip() {
  return import('@/core/common/buttons/TextButtonIconTooltip.vue');
};
var GridDataTableComponent = function GridDataTableComponent() {
  return import('@/core/common/data-table/grid-data-table/GridDataTableComponent.vue');
};
var HorizontalExpandableGridDataTableComponent = function HorizontalExpandableGridDataTableComponent() {
  return import('@/core/common/data-table/grid-data-table/HorizontalExpandableGridDataTableComponent.vue');
};
var GridListComponent = function GridListComponent() {
  return import('@/core/common/data-table/grid-list/GridListComponent.vue');
};
var ServerPagingList = function ServerPagingList() {
  return import('@/core/common/data-table/grid-list/ServerPagingList.vue');
};
var ServerPagingTable = function ServerPagingTable() {
  return import('@/core/common/data-table/server-paging-table/ServerPagingTable.vue');
};
var LocationOptionalImg = function LocationOptionalImg() {
  return import('@/core/common/LocationOptionalImg.vue');
};
var LoadingOverlay = function LoadingOverlay() {
  return import('@/core/components/LoadingOverlay.vue');
};
var DateInput = function DateInput() {
  return import('@/core/common/DateInput.vue');
};
var ButtonTooltip = function ButtonTooltip() {
  return import('@/core/common/buttons/ButtonTooltip.vue');
};
var ButtonIconTextTooltip = function ButtonIconTextTooltip() {
  return import('@/core/common/buttons/ButtonIconTextTooltip.vue');
};
var TimePicker = function TimePicker() {
  return import('@/core/common/form-inputs/TimePicker.vue');
};
var TwoTimesPicker = function TwoTimesPicker() {
  return import('@/core/common/form-inputs/TwoTimesPicker.vue');
};
var TwoDatesTimesPicker = function TwoDatesTimesPicker() {
  return import('@/core/common/form-inputs/TwoDatesTimesPicker.vue');
};
var DateTimePicker = function DateTimePicker() {
  return import('@/core/common/form-inputs/DateTimePicker.vue');
};
var PhotoUploader = function PhotoUploader() {
  return import('@/core/common/photos/uploader/admin/PhotoUploader.vue');
};
var VGridRow = function VGridRow() {
  return import('@/core/common/VGridRow.vue');
};
var SelectableLanguageList = function SelectableLanguageList() {
  return import('@/core/common/form-inputs/SelectableLanguageList.vue');
};
var AutocompleteTextInput = function AutocompleteTextInput() {
  return import('@/core/common/form-inputs/AutocompleteTextInput.vue');
};
var AutocompleteSelectOrAddNewInput = function AutocompleteSelectOrAddNewInput() {
  return import('@/core/common/form-inputs/AutocompleteSelectOrAddNewInput.vue');
};
var OrganizationAvatar = function OrganizationAvatar() {
  return import('@/core/common/OrganizationAvatar.vue');
};
var EditingItemNavigationTemplate = function EditingItemNavigationTemplate() {
  return import('@/core/item_navigation/components/EditingItemNavigationTemplate.vue');
};
var MultiLanguageCheckboxInput = function MultiLanguageCheckboxInput() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageCheckboxInput.vue');
};
var MultiLanguageHeading = function MultiLanguageHeading() {
  return import('@/core/common/form-inputs/multi-language-input/MultiLanguageHeading.vue');
};
var FormCard = function FormCard() {
  return import('@/core/common/FormCard.vue');
};
var RatingStar = function RatingStar() {
  return import('@/core/common/RatingStar.vue');
};
var MoscowButton = function MoscowButton() {
  return import('@/core/common/buttons/moscow_button/MoscowButton.vue');
};
Vue.component('moscow-button', MoscowButton);
Vue.component('rating-star', RatingStar);
Vue.component('multi-language-select-multiple-input-short-description', MultiLanguageSelectMultipleInputShortDescription);
Vue.component('organization-avatar', OrganizationAvatar);
Vue.component('selectable-language-list', SelectableLanguageList);
Vue.component('multi-language-select-input', MultiLanguageSelectInput);
Vue.component('photo-uploader', PhotoUploader);
Vue.component('two-dates-times-picker', TwoDatesTimesPicker);
Vue.component('date-time-picker', DateTimePicker);
Vue.component('two-times-picker', TwoTimesPicker);
Vue.component('date-input', DateInput);
Vue.component('time-input', TimePicker);
Vue.component('location-optional-img', LocationOptionalImg);
Vue.component('loading-overlay', LoadingOverlay);
Vue.component('text-input', TextInput);
Vue.component('multi-language-text-input', MultiLanguageTextInput);
Vue.component('multi-language-wrapper', MultiLanguageWrapper);
Vue.component('select-input', SelectInput);
Vue.component('autocomplete-input', AutocompleteInput);
Vue.component('purpose-multi-language-autocomplete-input', PurposeMultiLanguageAutocompleteInput);
Vue.component('multi-language-autocomplete-input', MultiLanguageAutocompleteInput);
Vue.component('multi-language-text-area', MultiLanguageTextArea);
Vue.component('multi-language-rich-text', MultiLanguageRichText);
Vue.component('checkbox-input', CheckboxInput);
Vue.component('radio-group-input', RadioGroupInput);
Vue.component('switch-input', SwitchInput);
Vue.component('two-label-switch', TwoLabelSwitch);
Vue.component('v-button', VButton);
Vue.component('small-button', SmallButton);
Vue.component('language-selector', LanguageSelector);
Vue.component('text-button', TextButton);
Vue.component('button-icon', ButtonIcon);
Vue.component('button-icon-with-background', ButtonIconWithBackground);
Vue.component('button-in-menu', ButtonInMenu);
Vue.component('button-icon-tooltip', ButtonIconToolTip);
Vue.component('v-modal', Modal);
Vue.component('v-modal-not-full-screen-in-mobile', ModalNotFullScreenInMobile);
Vue.component('card-in-modal', CardInModal);
Vue.component('anchored-heading', AnchoredHeading);
Vue.component('anchored-text', AnchoredText);
Vue.component('multi-row-anchored-text', MultiRowAnchoredTextWithTooltip);
Vue.component('full-anchored-text', FullAnchoredText);
Vue.component('responsive-image', ResponsiveImage);
Vue.component('cropped-responsive-image', CroppedResponsiveImage);
Vue.component('read-more-text', ReadMoreText);
Vue.component('data-table-component', DataTableComponent);
Vue.component('anchored-carousel', AnchoredCarousel);
Vue.component('icon-component', IconComponent);
Vue.component('no-data-in-list', NoDataInList);
Vue.component('photo-in-list', PhotoInList);
Vue.component('text-area-input', TextAreaInput);
Vue.component('select-multiple-input-short-description', SelectMultipleInputShortDescription);
Vue.component('price-input', PriceInput);
Vue.component('address-label', AddressLabel);
Vue.component('multi-language-label', MultiLanguageLabel);
Vue.component('text-button-icon-tooltip', TextButtonIconTooltip);
Vue.component('grid-data-table-component', GridDataTableComponent);
Vue.component('horizontal-expandable-grid-data-table-component', HorizontalExpandableGridDataTableComponent);
Vue.component('grid-list-component', GridListComponent);
Vue.component('server-paging-table', ServerPagingTable);
Vue.component('server-paging-list', ServerPagingList);
Vue.component('button-tooltip', ButtonTooltip);
Vue.component('button-icon-text-tooltip', ButtonIconTextTooltip);
Vue.component('v-grid-row', VGridRow);
Vue.component('icon-tooltip', IconToolTipComponent);
Vue.component('multi-language-icon-tooltip', MultiLanguageIconTooltipComponent);
Vue.component('in-list-warning', InListWarningComponent);
Vue.component('auto-complete-text-input', AutocompleteTextInput);
Vue.component('editing-item-navigation-template', EditingItemNavigationTemplate);
Vue.component('autocomplete-select-or-add-new-input', AutocompleteSelectOrAddNewInput);
Vue.component('multi-language-label-text-input', MultiLanguageLabelTextInput);
Vue.component('multi-language-checkbox-input', MultiLanguageCheckboxInput);
Vue.component('multi-language-heading', MultiLanguageHeading);
Vue.component('form-card', FormCard);