import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import EventRoles from '@/event_management_domain_new/event/constants/event-roles';
import values from 'lodash/values';
export default {
  CREATE_AND_PLAN_EVENT: {
    get text() {
      return 'register.role.create_and_plan_event#label';
    },
    value: 'CREATE_AND_PLAN_EVENT',
    icon: 'organization-type-planning-event-group',
    getTypesForCreateAndPlanEvent: function getTypesForCreateAndPlanEvent(i18n, possibleRoles) {
      if (!possibleRoles || possibleRoles.length === 0) {
        return [this.FOR_MYSELF, this.FOR_MY_COMPANY, this.FOR_MY_CLIENT].map(function (type) {
          return _objectSpread(_objectSpread({}, type), {}, {
            get text() {
              return i18n.t(type.text);
            },
            get orgTypeText() {
              return i18n.t(type.orgTypeText);
            }
          });
        });
      }
      var types = [];
      if (possibleRoles.includes(EventRoles.EVENT_CLIENT)) {
        types.push(this.FOR_MYSELF, this.FOR_MY_COMPANY);
      }
      if (possibleRoles.includes(EventRoles.EVENT_PLANNER)) {
        types.push(this.FOR_MY_CLIENT);
      }
      return types.map(function (type) {
        return _objectSpread(_objectSpread({}, type), {}, {
          get text() {
            return i18n.t(type.text);
          },
          get orgTypeText() {
            return i18n.t(type.orgTypeText);
          }
        });
      });
    },
    getNonPersonalClientTypesForCreateAndPlanEvent: function getNonPersonalClientTypesForCreateAndPlanEvent(i18n, possibleRoles) {
      if (!possibleRoles || possibleRoles.length === 0) {
        return [this.FOR_MY_COMPANY, this.FOR_MY_CLIENT].map(function (type) {
          return _objectSpread(_objectSpread({}, type), {}, {
            get text() {
              return i18n.t(type.text);
            },
            get orgTypeText() {
              return i18n.t(type.orgTypeText);
            }
          });
        });
      }
      var types = [];
      if (possibleRoles.includes(EventRoles.EVENT_CLIENT)) {
        types.push(this.FOR_MY_COMPANY);
      }
      if (possibleRoles.includes(EventRoles.EVENT_PLANNER)) {
        types.push(this.FOR_MY_CLIENT);
      }
      return types.map(function (type) {
        return _objectSpread(_objectSpread({}, type), {}, {
          get text() {
            return i18n.t(type.text);
          },
          get orgTypeText() {
            return i18n.t(type.orgTypeText);
          }
        });
      });
    },
    FOR_MYSELF: {
      value: 'personal_event_client',
      get text() {
        return 'register.role.for_my_self#label';
      }
    },
    FOR_MY_COMPANY: {
      value: 'business_event_client',
      get text() {
        return 'register.role.for_my_company#label';
      }
    },
    FOR_MY_CLIENT: {
      value: 'event',
      get text() {
        return 'register.role.for_my_client#label';
      }
    }
  },
  LIST_MY_COMPANY: {
    get text() {
      return 'register.role.list_my_company#label';
    },
    value: 'LIST_MY_COMPANY',
    icon: 'organization-type-list-company-group',
    getTypesForListingCompany: function getTypesForListingCompany(i18n, possibleRoles) {
      if (!possibleRoles || possibleRoles.length === 0) {
        return [this.TO_RENT_MY_VENUE, this.AS_EVENT_PLANNER, this.AS_SUPPLIER].map(function (type) {
          return _objectSpread(_objectSpread({}, type), {}, {
            get text() {
              return i18n.t(type.text);
            },
            get orgTypeText() {
              return i18n.t(type.orgTypeText);
            }
          });
        });
      }
      var types = [];
      if (possibleRoles.includes(EventRoles.EVENT_PLANNER)) {
        types.push(this.AS_EVENT_PLANNER);
      }
      if (possibleRoles.includes(EventRoles.VENUE_MANAGER)) {
        types.push(this.TO_RENT_MY_VENUE);
      }
      return types.map(function (type) {
        return _objectSpread(_objectSpread({}, type), {}, {
          get text() {
            return i18n.t(type.text);
          },
          get orgTypeText() {
            return i18n.t(type.orgTypeText);
          }
        });
      });
    },
    TO_RENT_MY_VENUE: {
      value: 'venue',
      get text() {
        return 'register.role.to_rent_my_venue#label';
      }
    },
    AS_EVENT_PLANNER: {
      value: 'event',
      get text() {
        return 'register.role.as_event_planner#label';
      }
    },
    AS_SUPPLIER: {
      value: 'supplier',
      get text() {
        return 'register.role.as_supplier#label';
      }
    }
  },
  PERSONAL_EVENT_CLIENT: {
    value: 'personal_event_client',
    get text() {
      return 'register.role.for_my_self#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.personal_client#vary';
    }
  },
  BUSINESS_EVENT_CLIENT: {
    value: 'business_event_client',
    get text() {
      return 'register.role.for_my_company#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.business_client#vary';
    }
  },
  VENUE_MANAGER: {
    value: 'venue',
    get text() {
      return 'register.role.to_rent_my_venue#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.venue_manager#vary';
    }
  },
  EVENT_PLANNER: {
    value: 'event',
    get text() {
      return 'register.role.as_event_planner#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.event_planner#vary';
    }
  },
  SUPPLIER: {
    value: 'supplier',
    get text() {
      return 'register.role.as_supplier#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.supplier#vary';
    }
  },
  EXHIBITOR: {
    value: 'exhibitor',
    get text() {
      return 'register.role.as_exhibitor#label';
    },
    get orgTypeText() {
      return 'admin.user_organization.exhibitor#vary';
    }
  },
  getAccountTypeGroups: function getAccountTypeGroups(possibleRoles) {
    if (!possibleRoles || possibleRoles.length === 0 || possibleRoles.includes(EventRoles.EVENT_PLANNER)) {
      return [this.CREATE_AND_PLAN_EVENT, this.LIST_MY_COMPANY];
    }
    var groups = [];
    if (possibleRoles.includes(EventRoles.EVENT_CLIENT)) {
      groups.push(this.CREATE_AND_PLAN_EVENT);
    }
    if (possibleRoles.includes(EventRoles.VENUE_MANAGER)) {
      groups.push(this.LIST_MY_COMPANY);
    }
    return groups;
  },
  getOrganizationTypes: function getOrganizationTypes(i18n) {
    return [this.VENUE_MANAGER, this.EVENT_PLANNER, this.PERSONAL_EVENT_CLIENT, this.BUSINESS_EVENT_CLIENT, this.SUPPLIER, this.EXHIBITOR].map(function (item) {
      return _objectSpread(_objectSpread({}, item), {}, {
        get text() {
          return i18n.t(item.text);
        },
        get orgTypeText() {
          return i18n.t(item.orgTypeText);
        }
      });
    });
  },
  findByValue: function findByValue(value) {
    return values(this).find(function (orgType) {
      return orgType.value === value;
    });
  }
};