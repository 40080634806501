import BEVERAGE_GROUP_ACTIONS from '@/catering_domain/venue_beverage_group_type/vuex/beverage-group-actions';
var BEVERAGE_GROUP_STORE = {
  state: function state() {
    return {
      beverageTypeHasChanged: false,
      /**
       * Check if any price has changed when mass editing beverage group prices
       */
      priceHasChanged: false
    };
  },
  mutations: {
    updateBeverageTypeHasChanged: function updateBeverageTypeHasChanged(state, hasChanged) {
      state.beverageTypeHasChanged = hasChanged;
    },
    updatePriceHasChanged: function updatePriceHasChanged(state, hasChanged) {
      state.priceHasChanged = hasChanged;
    }
  },
  getters: {
    beverageTypeHasChanged: function beverageTypeHasChanged(state) {
      return state.beverageTypeHasChanged;
    },
    isBeverageGroupChanged: function isBeverageGroupChanged(state) {
      return state.priceHasChanged || state.beverageTypeHasChanged;
    },
    priceHasChanged: function priceHasChanged(state) {
      return state.priceHasChanged;
    }
  },
  actions: {
    updatePriceHasChanged: function updatePriceHasChanged(_ref, priceHasChanged) {
      var commit = _ref.commit;
      commit(BEVERAGE_GROUP_ACTIONS.updatePriceHasChanged, priceHasChanged);
    },
    updateBeverageTypeHasChanged: function updateBeverageTypeHasChanged(_ref2, beverageTypeHasChanged) {
      var commit = _ref2.commit;
      commit(BEVERAGE_GROUP_ACTIONS.updateBeverageTypeHasChanged, beverageTypeHasChanged);
    },
    updateBeverageGroupHasChanged: function updateBeverageGroupHasChanged(_ref3, beverageGroupHasChanged) {
      var commit = _ref3.commit;
      commit(BEVERAGE_GROUP_ACTIONS.updatePriceHasChanged, beverageGroupHasChanged);
      commit(BEVERAGE_GROUP_ACTIONS.updateBeverageTypeHasChanged, beverageGroupHasChanged);
    }
  }
};
export default BEVERAGE_GROUP_STORE;