import SUPPLIER_ACTIONS from '@/supplier_domain/vuex/supplier-actions';
var SupplierStore = {
  state: function state() {
    return {
      supplierUserLinks: [],
      supplierId: '',
      supplierOnline: false
    };
  },
  getters: {
    supplierId: function supplierId(state) {
      return state.supplierId;
    },
    supplierOnline: function supplierOnline(state) {
      return state.supplierOnline;
    },
    supplierUserLinks: function supplierUserLinks(state) {
      return state.supplierUserLinks;
    }
  },
  actions: {
    updateSupplierId: function updateSupplierId(_ref, supplierId) {
      var commit = _ref.commit;
      commit(SUPPLIER_ACTIONS.updateSupplierId, supplierId);
    },
    updateLoggedInSupplier: function updateLoggedInSupplier(_ref2, supplierInformation) {
      var commit = _ref2.commit;
      commit(SUPPLIER_ACTIONS.updateLoggedInSupplier, supplierInformation);
    },
    updateSupplierUserLinks: function updateSupplierUserLinks(_ref3, supplierUserLinks) {
      var commit = _ref3.commit;
      commit(SUPPLIER_ACTIONS.updateSupplierUserLinks, supplierUserLinks);
    },
    updateSupplierOnline: function updateSupplierOnline(_ref4, supplierOnline) {
      var commit = _ref4.commit;
      commit(SUPPLIER_ACTIONS.updateSupplierOnline, supplierOnline);
    }
  },
  mutations: {
    updateSupplierId: function updateSupplierId(state, supplierId) {
      state.supplierId = supplierId;
    },
    updateLoggedInSupplier: function updateLoggedInSupplier(state, supplierInformation) {
      if (supplierInformation) {
        state.supplierId = supplierInformation.supplierId;
        state.supplierOnline = supplierInformation.online;
      }
    },
    updateSupplierOnline: function updateSupplierOnline(state, supplierOnline) {
      state.supplierOnline = supplierOnline;
    },
    updateSupplierUserLinks: function updateSupplierUserLinks(state, supplierUserLinks) {
      state.supplierUserLinks = supplierUserLinks;
    }
  }
};
export default SupplierStore;