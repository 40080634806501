/* eslint-disable no-console */
import Vue from 'vue';
import axios from 'axios';
import promiseFinally from 'promise.prototype.finally';
import bus from '@/core/event-bus';
import router from '@/core/routes/router';
import qs from 'qs';
import VuexStore from '@/core/config/vuex';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
promiseFinally.shim();
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(function (config) {
  var accessToken = localStorage.getItem('accessToken');
  config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  if (accessToken && !config.noAuthentication) {
    config.headers.common.Authorization = "Bearer ".concat(accessToken);
  }
  config.paramsSerializer = function (params) {
    return qs.stringify(params);
  };
  return config;
});
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data === 'invalid_token') {
    localStorage.removeItem('refresh-access-token-link');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expired-time');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    localStorage.removeItem('organizationId');
    VuexStore.commit(AUTHENTICATION_ACTIONS.logOut);
    sessionStorage.clear();
    router.push('/');
    bus.$emit('axios#token#expired');
  }
  if (error.response && 'unauthorized' === error.response.data) {
    router.push('/');
  }
  bus.$emit('loading:error');
  return Promise.reject(error);
});
Vue.prototype.$http = axios;
Vue.prototype.$to = function to(promise) {
  return promise.then(function (response) {
    return [response, null];
  }).catch(function (error) {
    return [null, error];
  });
};