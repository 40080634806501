var locationEndpoint = '/v1/location';
var LocationAPIs = {
  FETCH_LOCATION: function FETCH_LOCATION(organizationId, locationId) {
    return "".concat(locationEndpoint, "/organizations/").concat(organizationId, "/venues/").concat(locationId);
  },
  TOGGLE_FAVORITE_LOCATION: function TOGGLE_FAVORITE_LOCATION(locationId) {
    return "".concat(locationEndpoint, "/favorite/").concat(locationId);
  },
  CHECK_EVENT_EXIST_IN_DAY_BLOCK: function CHECK_EVENT_EXIST_IN_DAY_BLOCK(locationId) {
    return "".concat(locationEndpoint, "/").concat(locationId, "/dayBlock/used");
  },
  SEND_LOCATION_MESSAGE: function SEND_LOCATION_MESSAGE(locationId) {
    return "".concat(locationEndpoint, "/").concat(locationId, "/message");
  },
  GET_VENUE_COMPLETION_INFORMATION: function GET_VENUE_COMPLETION_INFORMATION(venueId) {
    return "/v1/location/venue-level/venue-completion-information/".concat(venueId);
  }
};
export default LocationAPIs;