import store from '@/core/config/vuex';
import AUTHENTICATION_ACTIONS from '@/identity_domain/authentication/vuex/authentication-actions';
var InvitingNewVMForPriceQuoteAuthentication = {
  // eslint-disable-next-line no-unused-vars
  authenticate: function authenticate(_ref) {
    var to = _ref.to,
      from = _ref.from,
      next = _ref.next;
    if (store.getters.isLoggedIn) {
      next();
    } else {
      store.dispatch(AUTHENTICATION_ACTIONS.openLoginModal, to);
    }
  }
};
export default InvitingNewVMForPriceQuoteAuthentication;