import VenueAuthorization from '@/venue_management_domain/authorizations/venue-authorization';
import VenueAuthentication from '@/venue_management_domain/authentications/venue-authentication';
import VenueListAuthorization from '@/venue_management_domain/authorizations/venue-list-authorization';
import CreatingVenueAuthorization from '@/venue_management_domain/authorizations/creating-venue-authorization';
import OrganizationTypes from '@/identity_domain/register/constants/organization-types-enum';
import VenueSettingPageSidebarGroups from '@/venue_management_domain/venue_settings/constants/venue-setting-page-sidebar-groups';
export default [{
  path: '/venues/:locationId/event-templates/:eventTemplateId',
  name: 'location-settings-event-template',
  component: function component() {
    return import('@/event_booking_domain/venue_event_template/components/VenueEventTemplateContainer.vue');
  },
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: VenueAuthorization
  },
  props: true
}, {
  path: '/locations/:venueSeoId',
  name: 'location-profile',
  component: function component() {
    return import('@/public_domain/venue_profile/components/VenueProfile.vue');
  },
  props: true
}, {
  path: '/locations/:locationId/message',
  name: 'send-message-to-location',
  component: function component() {
    return import('@/communication_domain/organization_message/component/SendLocationMessage.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.EVENT_PLANNER.value, OrganizationTypes.VENUE_MANAGER.value],
    authentication: VenueAuthentication,
    authorization: VenueAuthorization
  }
}, {
  path: '/venue/location-list',
  name: 'location-list',
  component: function component() {
    return import('@/venue_management_domain/venue_information/components/venue_list/VenueList.vue');
  },
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: VenueListAuthorization
  }
}, {
  path: '/venue/locations/new',
  name: 'new-location',
  component: function component() {
    return import('@/venue_management_domain/venue_information/components/CreatingNewLocationForm.vue');
  },
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: CreatingVenueAuthorization
  }
}, {
  path: '/venue/internal-venues/new',
  name: 'new-internal-venue',
  component: function component() {
    return import('@/venue_management_domain/venue_information/components/CreatingNewInternalVenueForm.vue');
  },
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: CreatingVenueAuthorization
  }
}, {
  path: '/admin/venues/:locationId',
  name: 'setup-venue-by-admin',
  component: function component() {
    return import('@/venue_management_domain/admin_managing_venue/AdminManagingVenueContainer.vue');
  },
  props: true,
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: VenueAuthorization
  }
}, {
  path: '/venues/:locationId/setup-venue',
  name: 'setup-venue-by-vm',
  component: function component() {
    return import('@/venue_management_domain/vm_setup_venue/VenueManagerManagingVenueContainer.vue');
  },
  props: true,
  meta: {
    roles: ['ORGANIZATION_ADMIN']
  }
}, {
  path: '/venue/locations/:locationId',
  name: 'edit-location',
  component: function component() {
    return import('@/venue_management_domain/venue_settings/layouts/VenueSettingsView.vue');
  },
  props: true,
  meta: {
    roles: ['ORGANIZATION_ADMIN'],
    authentication: VenueAuthentication,
    authorization: VenueAuthorization
  },
  children: [{
    path: '',
    redirect: 'general-information',
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    }
  }, {
    path: 'general-information',
    name: 'venue-information-general',
    component: function component() {
      return import('@/venue_management_domain/venue_information/components/VenueGeneralInformationContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    },
    props: true
  }, {
    path: 'address',
    name: 'venue-information-address',
    component: function component() {
      return import('@/venue_management_domain/venue_address/VenueAddressContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    },
    props: true
  }, {
    path: 'properties',
    name: 'venue-information-properties',
    component: function component() {
      return import('@/venue_management_domain/venue_property/components/VenuePropertyContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    },
    props: true
  }, {
    path: 'facilities',
    name: 'venue-facilities',
    component: function component() {
      return import('@/venue_management_domain/venue_facility/components/VenueFacilityContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    },
    props: true
  }, {
    path: 'photos',
    name: 'venue-photos',
    component: function component() {
      return import('@/venue_management_domain/venue_image/components/VenuePhotoContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.INFORMATION.value
    },
    props: true
  }, {
    path: 'day-blocks',
    name: 'venue-settings-time-blocks',
    component: function component() {
      return import('@/venue_management_domain/venue_time_block/components/VenueTimeBlockContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'overnight-stay',
    name: 'venue-settings-overnight-stay',
    component: function component() {
      return import('@/venue_management_domain/venue_overnight_stay/components/OvernightStayContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'turnover-accounts',
    name: 'venue-settings-turnover-accounts',
    component: function component() {
      return import('@/financial_domain/venue_turnover_account/components/VenueTurnoverAccountContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'basic-price-information',
    name: 'venue-settings-basic-price-information',
    component: function component() {
      return import('@/venue_management_domain/venue_basic_price_information/components/BasicPriceInformationContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'overnight-stay',
    name: 'venue-settings-overnight-stay',
    component: function component() {
      return import('@/venue_management_domain/venue_overnight_stay/components/OvernightStayContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'supplier',
    name: 'venue-settings-supplier',
    component: function component() {
      return import('@/venue_management_domain/venue_supplier_acceptance_setting/components/VenueSupplierAcceptanceSettingContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'basic-price-information/basic-arrangements/:itemTypeId',
    name: 'location-basic-arrangement-item-edit',
    component: function component() {
      return import('@/venue_management_domain/venue_basic_price_information/components/BasicArrangementItem.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'item-price-periods',
    name: 'location-settings-item-price-periods',
    component: function component() {
      return import('@/financial_domain/venue_item_prices/components/VenueItemPrices.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'venue-fee-settings',
    name: 'venue-fee-settings',
    component: function component() {
      return import('@/financial_domain/venue_fee_settings/VenueFeeSettings.vue');
    },
    meta: {
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'venue-search-price-settings',
    name: 'venue-search-price-settings',
    component: function component() {
      return import('@/event_booking_domain/venue_search_price_settings/VenueSearchPriceSettingsContainer.vue');
    },
    meta: {
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'activities',
    name: 'venue-settings-activities',
    component: function component() {
      return import('@/property_management_domain/venue_activity/components/VenueActivityContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'purposes',
    name: 'venue-settings-purposes',
    component: function component() {
      return import('@/venue_management_domain/venue_purpose/components/VenuePurposeContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'event-templates',
    name: 'location-settings-event-templates',
    component: function component() {
      return import('@/event_booking_domain/venue_event_template/components/VenueEventTemplates.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'widget',
    name: 'location-settings-widget',
    component: function component() {
      return import('@/venue_management_domain/venue_widget/components/Widget.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.SETTINGS.value
    },
    props: true
  }, {
    path: 'area-types',
    name: 'venue-area-type-list',
    component: function component() {
      return import('@/property_management_domain/venue_area/area-type-list/VenueAreaTypeListContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'area-type-list-for-time-block',
    name: 'venue-area-type-list-for-time-block',
    component: function component() {
      return import('@/property_management_domain/venue_area_time_blocks_and_prices/components/VenueAreaTypeListForTimeBlock.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'area-type-time-blocks-and-prices/:areaTypeId',
    name: 'venue-area-type-time-blocks-and-prices',
    component: function component() {
      return import('@/property_management_domain/venue_area_time_blocks_and_prices/components/VenueAreaTypeTimeBlocksAndPrices.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'area-types/:areaTypeId',
    name: 'venue-area-type',
    component: function component() {
      return import('@/property_management_domain/venue_area/area-type-detail/NewVenueAreaTypeDetailContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'placements-price-periods',
    name: 'venue-placement-prices-in-all-area-types',
    component: function component() {
      return import('@/financial_domain/venue_item_prices/components/placement_prices/VenuePlacementPrices.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'whole-venue-rental-setting',
    name: 'whole-venue-rental-setting',
    component: function component() {
      return import('@/property_management_domain/whole_venue_rental_setting/components/WholeVenueRentalSetting.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.AREAS.value
    },
    props: true
  }, {
    path: 'food-types',
    name: 'location-food-type-list',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/food_types/VenueFoodItemsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'food-categories',
    name: 'location-food-type-categories',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/food_categories/VenueFoodCategoriesContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'food-categories/:categoryId',
    name: 'location-food-type-category-edit',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/food_categories/VenueFoodCategoryContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'food-types/:itemTypeId',
    name: 'location-food-type-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/food_types/VenueFoodItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'basic-food-types/item-type/:itemTypeId',
    name: 'location-food-type-basic-item-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/food_types/VenueBasicFoodItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'beverage-types',
    name: 'location-beverage-type-list',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/beverage_types/VenueBeverageItemsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'beverage-categories',
    name: 'location-beverage-type-categories',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/beverage_categories/VenueBeverageCategoriesContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'beverage-categories/:categoryId',
    name: 'location-beverage-type-category-edit',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/beverage_categories/VenueBeverageCategoryContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'beverage-types/:itemTypeId',
    name: 'location-beverage-type-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/beverage_types/VenueBeverageItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'beverage-groups',
    name: 'location-beverage-group-view',
    component: function component() {
      return import('@/catering_domain/venue_beverage_group_type/components/VenueBeverageGroupsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'rentable-types',
    name: 'location-rentable-resource-type-list',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/rentable_types/VenueRentableItemsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'rentable-categories',
    name: 'location-rentable-resource-type-categories',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/rentable_categories/VenueRentableCategoriesContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'rentable-categories/:categoryId',
    name: 'location-rentable-resource-type-category-edit',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/rentable_categories/VenueRentableCategoryContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'rentable-types/:itemTypeId',
    name: 'location-rentable-resource-type-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/rentable_types/VenueRentableItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'basic-rentable-types/item-type/:itemTypeId',
    name: 'location-rentable-resource-type-basic-item-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/rentable_types/VenueBasicRentableItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'consumable-types',
    name: 'location-consumable-resource-type-list',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/consumable_types/VenueConsumableItemsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'consumable-categories',
    name: 'location-consumable-resource-type-categories',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/consumable_categories/VenueConsumableCategoriesContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'consumable-categories/:categoryId',
    name: 'location-consumable-resource-type-category-edit',
    component: function component() {
      return import('@/catering_domain/venue_item_categories/components/consumable_categories/VenueConsumableCategoryContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'consumable-types/:itemTypeId',
    name: 'location-consumable-resource-type-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/consumable_types/VenueConsumableItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'basic-consumable-types/:itemTypeId',
    name: 'location-consumable-resource-type-basic-item-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/consumable_types/VenueBasicConsumableItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'human-types',
    name: 'location-human-resource-type-list',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/human_resource_types/VenueHumanResourceItemsContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'human-types/:itemTypeId',
    name: 'location-human-resource-type-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/human_resource_types/VenueHumanResourceItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'basic-human-types/item-type/:itemTypeId',
    name: 'location-human-resource-type-basic-item-edit',
    component: function component() {
      return import('@/catering_domain/venue_other_item_types/components/human_resource_types/VenueBasicHumanResourceItemContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization
    },
    props: true
  }, {
    path: 'event-payment-information',
    name: 'venue-event-payment-information',
    component: function component() {
      return import('@/venue_management_domain/venue_event_payment_information/components/VenueEventPaymentInformationContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.EVENT_PAYMENT.value
    },
    props: true
  }, {
    path: 'terms-and-conditions',
    name: 'venue-event-payment-terms-and-conditions',
    component: function component() {
      return import('@/venue_management_domain/venue_event_payment_information/components/VenuePolicyContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.EVENT_PAYMENT.value
    },
    props: true
  }, {
    path: 'invoice-template',
    name: 'location-event-payment-invoice-template',
    component: function component() {
      return import('@/financial_domain/invoice_template/components/VenueInvoiceTemplateContainer.vue');
    },
    meta: {
      roles: ['ORGANIZATION_ADMIN'],
      authentication: VenueAuthentication,
      authorization: VenueAuthorization,
      group: VenueSettingPageSidebarGroups.EVENT_PAYMENT.value
    },
    props: true
  }]
}, {
  path: '/compare',
  name: 'location-comparison',
  component: function component() {
    return import('@/event_booking_domain/venue_compare/components/ComparePage.vue');
  }
}, {
  path: '/venue-item-categories',
  name: 'venue-item-categories',
  component: function component() {
    return import('@/catering_domain/organization_item_categories/components/OrganizationItemCategories.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value]
  }
}, {
  path: '/venue-item-categories/:itemCategoryId',
  name: 'venue-item-category',
  component: function component() {
    return import('@/catering_domain/organization_item_categories/components/OrganizationItemCategory.vue');
  },
  props: true,
  meta: {
    roles: [OrganizationTypes.VENUE_MANAGER.value]
  }
}];